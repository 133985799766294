import React, { useEffect, useState } from "react";
import ClickawayTooltipIcon from "@/components/TooltipIcon/ClickawayTooltipIcon";
import { adminService } from "@/services";
import { GetWidgetFilterType, WidgetEntity, WidgetEntityDictionary } from "@/domain/admin";
import CompanyListModal from "@/admin/dashboard/CompanyListModal";
import PlaceholderLoading from "@/components/PlaceholderLoading";

const WidgetRole = {
  logist: "Логист",
  "support-logist": "Помощник логиста",
  accountant: "Бухгалтер",
};

export const WidgetDictionary: Record<GetWidgetFilterType, WidgetEntityDictionary> = {
  mover_not_assigned: {
    title: "Не назначен Перевозчик",
    description: "Активные заказы, на которые не назначен перевозчик",
    modal: null,
    role: "logist",
  },
  unverified_orders: {
    title: "Не проверенные заказы",
    description:
      "Заказы у которых прошла дата доставки, но не стоит статус контейнер сдан. Исключаем отменённые заказы",
    modal: null,
    role: "logist",
  },
  no_point: {
    title: "Не проставлена точка",
    description:
      "Заказы у которых прошла дата доставки, но не стоит место сдачи / место постановки. Исключаем отменённые заказы",
    modal: null,
    role: "logist",
  },
  expired_td: {
    title: "Просрочены ТД",
    description:
      "Заказы у которых прошло 3 дня от даты доставки, но не загружены транспортные документы от Перевозчика. Исключаем отменённые заказы",
    modal: "expired_td",
    role: "support-logist",
  },
  expired_bd: {
    title: "Просрочены БД",
    description:
      "Заказы у которых прошло 5 дней от даты доставки, но не подгружены Бухгалтерские документы от Перевозчика. Исключение отменённые заказы",
    modal: "expired_bd",
    role: "support-logist",
  },
  implementation_not_exposed: {
    title: "Не выставлена реализация",
    description: "Заказы у которых подгружены ТД, но не выставлена реализация Заказчику. Исключаем отменённые заказы",
    modal: "implementation_not_exposed",
    role: "accountant",
  },
  package_documents_not_generated: {
    title: "Не сформирован пакет документов",
    description: "Заказы у которых подгружены ТД и БД, но не сформирован Пакет документов",
    modal: "package_documents_not_generated",
    role: "accountant",
  },
  late_payments: {
    title: "Просроченные оплаты",
    description: "Сформирована заявка на вывод, но не оплачено Перевозчику в течение 10 дней",
    modal: null,
    role: "accountant",
  },
  overdue_accounts_receivable: {
    title: "Просроченная дебиторка",
    description:
      "Заказы у которых прошло 30 дней от выставления реализации, но не поступила оплата от Заказчика. Исключаем отменённые заказы",
    modal: "overdue_accounts_receivable",
    role: "accountant",
  },
  no_closed_orders: {
    title: "Не закрытые заказы",
    description:
      "Заказы у которых не стоит статус закрыт, как у Заказчика, так и у Перевозчика. Исключаем отменённые заказы",
    modal: null,
    role: "support-logist",
  },
};

const Widgets = () => {
  return (
    <div className="flex w-full flex-col">
      <div className="grid h-fit w-full grid-cols-3 grid-rows-1 gap-10 p-10 pb-0">
        {Object.values(WidgetRole).map((item) => (
          <div className="h-fit text-textMediumSemi">{item}</div>
        ))}
      </div>
      <div className="grid h-fit w-full grid-cols-3 grid-rows-1 gap-10 p-10">
        {Object.keys(WidgetRole).map((key) => (
          <div className="flex flex-col gap-4">
            {Object.entries(WidgetDictionary)
              .filter(([k, v]) => v.role === key)
              .map(([k, v]) => (
                <WidgetItem filter={k as GetWidgetFilterType} />
              ))}
          </div>
        ))}
      </div>
    </div>
  );
};

export default Widgets;

interface WidgetItemProps {
  filter: GetWidgetFilterType;
}

const WidgetItem = (props: WidgetItemProps) => {
  const [widget, setWidget] = useState<WidgetEntity | null>(null);
  const [isOpen, setIsOpen] = useState<null | WidgetEntity>(null);

  const handleOpenModal = (widget: WidgetEntity) => {
    setIsOpen(widget);
  };

  const handleRedirect = (url: string) => {
    window.open(url, "_blank", "noopener noreferrer");
  };

  useEffect(() => {
    async function fetchWidget() {
      const response = await adminService.getWidgets({
        aggregate_filter: props.filter,
      });
      setWidget(response);
    }

    fetchWidget();
  }, []);

  return (
    <React.Fragment>
      {!widget ? (
        <PlaceholderLoading shape="rect" width={"100%"} height={60} />
      ) : (
        <div className="relative h-fit w-full rounded-xl border border-divider bg-white p-4 tablet:bg-bgDisabled">
          <div className="flex flex-col gap-2">
            <div className="flex items-center justify-between gap-2">
              <div
                className="group flex cursor-pointer gap-1 text-textBodySemi"
                onClick={() => handleRedirect(`/admin/orders/list?aggregate_filter=${widget.id}`)}
              >
                <p className="text-secondaryText group-hover:text-label">{widget.title}:</p>
                <p className="group-hover:text-label">{widget.count}</p>
              </div>

              <ClickawayTooltipIcon
                iconClassName="h-6 w-6 cursor-pointer"
                tooltipClassName="z-10 !-right-[40%] after:!left-[92%] !translate-x-0"
                placement="bottom"
                icon={
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="25"
                    height="24"
                    viewBox="0 0 25 24"
                    className="fill-disabled group-hover:fill-label group-active:fill-secondaryText"
                  >
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M23.3333 12C23.3333 14.9174 22.1743 17.7153 20.1114 19.7782C18.0485 21.8411 15.2506 23 12.3333 23C9.41587 23 6.61798 21.8411 4.55508 19.7782C2.49218 17.7153 1.33325 14.9174 1.33325 12C1.33325 9.08262 2.49218 6.28473 4.55508 4.22183C6.61798 2.15893 9.41587 1 12.3333 1C15.2506 1 18.0485 2.15893 20.1114 4.22183C22.1743 6.28473 23.3333 9.08262 23.3333 12ZM13.7083 6.5C13.7083 6.86467 13.5634 7.21441 13.3055 7.47227C13.0477 7.73013 12.6979 7.875 12.3333 7.875C11.9686 7.875 11.6188 7.73013 11.361 7.47227C11.1031 7.21441 10.9583 6.86467 10.9583 6.5C10.9583 6.13533 11.1031 5.78559 11.361 5.52773C11.6188 5.26987 11.9686 5.125 12.3333 5.125C12.6979 5.125 13.0477 5.26987 13.3055 5.52773C13.5634 5.78559 13.7083 6.13533 13.7083 6.5ZM10.9583 10.625C10.5936 10.625 10.2438 10.7699 9.98598 11.0277C9.72812 11.2856 9.58325 11.6353 9.58325 12C9.58325 12.3647 9.72812 12.7144 9.98598 12.9723C10.2438 13.2301 10.5936 13.375 10.9583 13.375V17.5C10.9583 17.8647 11.1031 18.2144 11.361 18.4723C11.6188 18.7301 11.9686 18.875 12.3333 18.875H13.7083C14.0729 18.875 14.4227 18.7301 14.6805 18.4723C14.9384 18.2144 15.0833 17.8647 15.0833 17.5C15.0833 17.1353 14.9384 16.7856 14.6805 16.5277C14.4227 16.2699 14.0729 16.125 13.7083 16.125V12C13.7083 11.6353 13.5634 11.2856 13.3055 11.0277C13.0477 10.7699 12.6979 10.625 12.3333 10.625H10.9583Z"
                    />
                  </svg>
                }
              >
                <div className="flex flex-col gap-2">
                  <p className="text-textSmallSemi">{widget.description}</p>
                </div>
              </ClickawayTooltipIcon>
            </div>
            {widget.modal && (
              <div
                className="w-fit cursor-pointer text-textBody underline underline-offset-[3px] hover:text-label"
                onClick={() => handleOpenModal(widget)}
              >
                Список компаний
              </div>
            )}
          </div>
        </div>
      )}

      <CompanyListModal filter={isOpen?.modal ?? null} open={!!isOpen} onClose={() => setIsOpen(null)} />
    </React.Fragment>
  );
};
