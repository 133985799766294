import { admin, auth, tickets } from "@/api/kuber-api";
import {
  AdminMenuItem,
  CalculateTariffRequest,
  CreateTn2022Request,
  Get1CCompanyListRequest,
  GetAdminAllManualCheckListRequest,
  GetAdminBankPaymentListRequest,
  GetAdminCreditListRequest,
  GetAdminDigitizationListRequest,
  GetAdminInvoiceListRequest,
  GetAdminOperationListRequest,
  GetAdminPostpaymentListRequest,
  GetAdminWithdrawListRequest,
  GetBriefDocPackageListRequest,
  GetBriefOrderListRequest,
  GetCheckDetailsResponse,
  GetCustomerDocsPackageListRequest,
  GetMoverDocsListRequest,
  GetMoverDocsPackageListRequest,
  GetMoverTransportDocsListRequest,
  GetPromosRequest,
  GetTicketChatRequest,
  GetUserInfoResponse,
  GetWidgetCompanyFilterRequest,
  GetWidgetFilterRequest,
  RecalculateTariffOrderEntityResponse,
  SearchUsersRequest,
  SearchUsersResponse,
  TariffOrderEntityResponse,
  WidgetEntity,
} from "@/domain/admin";
import {
  AuthTokensResponse,
  EntityWithProfileRequest,
  FIRST_MILE_DELIVERY_TYPE,
  GetPaginatedAdminTicketChatsListResponse,
  GetTicketChatDirectoryResponse,
  LAST_MILE_DELIVERY_TYPE,
  ResultResponse,
  SignInRequest,
  UniqueEntity,
} from "@/domain";
import { transformError } from "@/shared";
import { EditUserFormState, ManualDigitizationFormState, UncheckedVerificationsRequest } from "@/admin/data/form/types";
import {
  AddBankBillAdminController_BankPersonalBillAGRequest,
  AddBankStatementBillAdminController_BankStatementBillAGAdminRequest,
  AddContainerNumberToOrderController_AgAddContainerNumberToOrderRequest,
  AddCreditCustomerAdminController_CreditCustomerRequest,
  AddDocumentToOrderController_AgAddDocumentToOrderRequest,
  AddFileToMoverDocumentPackageAdminController_AgAddFileToMoverDocumentPackageRequest,
  AdminRecalculationTariffController_RecalculationTariffAdminRequest,
  AdminTariffEmptyMileController_PublicTariffCalculationEmptyContainerRequest,
  AdminTariffsFirstMileController_PublicTariffCalculationFirstMileRequest,
  AdminTariffsLastMileController_PublicTariffCalculationLastMileRequest,
  AdvancedCustomerCreditRepaymentAdminController_CustomerCreditRepaymentAdminAGRequest,
  type AgGetMoverDocumentPackageBriefInfoListAdminItemEntity,
  type AgGetOrderBriefInfoListAdminItemEntity,
  AgOrderIdCodeListEntity,
  type AgOrderStatusEntity,
  CancelOrderAdminController_AgOrderCancelRequest,
  ChangeContainerDeliveredAtController_AgChangeContainerDeliveredAtRequest,
  ChangeTransportOwnerController_ChangeTransportOwnerRequest,
  ChangeUserRolesController_ChangeUserRolesRequest,
  CloseTicketController_TicketCloseRequest,
  CompletedWorkActEntity,
  CreateNewMoverDocumentPackageAdminController_AgCreateNewMoverDocumentPackageRequest,
  CreatePromoCodesAdminController_NewPromoCodesRequest,
  DeclineDocumentDigitizationAdminController_AgDeclineDigitizationAdminRequest,
  DeleteTicketMessageAdminController_TicketMessageDeleteAdminRequest,
  FinishOrderCustomerController_AgOrderIdCodeRequest,
  FinishOrderMoverController_AgOrderIdCodeRequest,
  GenerateClosingDocumentsForOrders_AgGenerateClosingDocumentsForOrdersRequest,
  GenerateCustomerDocumentPackageController_AgGenerateCustomerDocumentPackageRequest,
  GetAllDigitizationsInStatusResponseEntity,
  GetAllManualChecksController_WorkflowAllManualChecksResponse,
  GetAllOrdersWithoutContainerNumberResponseEntity,
  GetBillingApplicationWithdrawAdminController_BillingApplicationWithdrawAdminResponse,
  GetBillingBankBillAdminController_BankBillOperationAdminResponse,
  GetBillingCreditIssuedCustomerAdminController_CreditIssuedCustomerResponse,
  GetBillingCreditOrderCustomerAdminController_CreditOrderCustomerAdminResponse,
  GetBillingHistoryPersonalOperationAdminController_HistoryPersonalOperationAdminResponse,
  GetBillingInvoiceAdminController_BillingInvoiceAdminResponse,
  GetClosedTicketInfoByAdminController_ClosedTicketInfoAdminResponse,
  GetCompanyWith1CInfoListAdminController_AgCompanyWith1CInfoListAdminResponse,
  GetCustomerDocumentPackageEntityListAdminController_AgCustomerDocumentPackageListAdminResponse,
  GetDigitizationsListAdminController_AgGetDigitizationsListAdminResponse,
  GetDriverListController_DriverListRequest,
  GetDriverListController_DriverListResponse,
  GetMoverDocumentPackageEntityListAdminController_AgMoverDocumentPackageEntityListAdminResponse,
  GetMoverDocumentPackageFileEntityListAdminController_AgMoverDocumentPackageFileEntityListAdminResponse,
  GetMoverTransportDocumentsListAdminController_AgMoverTransportDocumentsListAdminResponse,
  GetOrderListAdminController_AgGetOrderListAdminRequest,
  GetOrderListAdminController_AgOrderListAdminResponse,
  GetPromoCodesCustomerAdminController_TariffCalculationPromoCodesCustomerAdminResponse,
  GetSemiTrailerListController_SemiTrailerListResponse,
  GetSemiTrailerListController_TruckAndSemiTrailerListRequest,
  GetTicketIdAdminController_TicketIdNullableResponse,
  GetTicketIdAdminController_TicketTitleAndUserCodeRequest,
  GetTruckListController_TruckAndSemiTrailerListRequest,
  GetTruckListController_TruckListResponse,
  GetUserIdByParamsController_UserIdResponse,
  GetUserInfoByAdminController_UserIdAdminRequest,
  GetUserRolesController_UserRolesResponse,
  type ListCompanyWidgetOrdersOut,
  MoveTicketMessageController_TicketMessageMoveRequest,
  OpenTicketAdminController_TicketAndTicketMessageAdminRequest,
  PromoCodeAdminEntity,
  SendManualCheckController_ManualCheckRequest,
  SetCustomerDocumentPackageOriginalDocumentsSentController_AgSetCustomerDocumentPackageOriginalDocumentsSentRequest,
  SetMoverDocumentPackageFileOriginalReceivedController_AgMoverDocumentPackageFileOriginalReceivedRequest,
  SetMoverTransportDocumentOriginalReceivedController_AgMoverTransportDocumentOriginalReceivedRequest,
  SetOrderStatusController_AgSetOrderStatusWithParamsRequest,
  UpdateContractor1CInfoAdminController_AgContractor1CInfoAdminRequest,
  UpdateMoverDocumentPackageFileAdminController_AgUpdateMoverDocumentPackageFileStatusRequest,
  UpdateStatusPromCodeAdminController_StatusPromoCodeRequest,
  UpdateTariffAdminController_AgRecalculationOrderTariffAdminRequest,
  UploadMoverDocumentPackageFileAdminController_AgUploadMoverDocumentPackageFileAdminRequest,
  type UserShortInfoEntity,
  UserSpoofingController_SecurityTokenResponse,
  WithdrawMoverBalanceAdminController_ApplicationWithdrawAdminRequest,
  type WorkflowCheckEntity,
} from "@/api/kuber-api/codegen";
import { roles } from "@/data/constants";
import {
  toRecalculateTariffOrderEntityResponse,
  toTariffOrderEntityResponse,
} from "@/api/kuber-api/admin/admin-mappers";
import { WidgetDictionary } from "@/admin/dashboard/Widgets";

export function createTn2022Manually(request: CreateTn2022Request) {
  return admin.api
    .createTn2022Manually(admin.mappers.fromCreateTn2022Request(request))
    .then(admin.mappers.toCreateTn2022Response);
}

export async function checkVerificationManually(request: SendManualCheckController_ManualCheckRequest) {
  return admin.api
    .checkVerificationManually(request)
    .then((response) => response)
    .catch((error) => {
      throw transformError(error);
    });
}

export async function generateClosingDocs(
  request: GenerateClosingDocumentsForOrders_AgGenerateClosingDocumentsForOrdersRequest,
) {
  return admin.api
    .generateClosingDocs(request)
    .then((response) => response)
    .catch((error) => {
      throw transformError(error);
    });
}

export async function setOrderStatus(request: SetOrderStatusController_AgSetOrderStatusWithParamsRequest) {
  return admin.api
    .setOrderStatus(request)
    .then((response) => response)
    .catch((error) => {
      throw transformError(error);
    });
}

export async function getAllManualDigitizations(): Promise<GetAllDigitizationsInStatusResponseEntity[]> {
  return admin.api
    .getAllManualDigitizations()
    .then((response) => response.entities ?? [])
    .catch((error) => {
      throw transformError(error);
    });
}

export async function getAllUserVerifications(request: UncheckedVerificationsRequest) {
  try {
    return admin.api.getAllUserVerifications(request).then((data) => data.check_uuid_list);
  } catch (error) {
    throw transformError(error);
  }
}

export async function verifyManualDigitization(request: ManualDigitizationFormState) {
  return admin.api
    .verifyManualDigitization(request)
    .then((response) => response.entity)
    .catch((error) => {
      throw transformError(error);
    });
}

export async function finishOrder(
  request: EntityWithProfileRequest<
    FinishOrderCustomerController_AgOrderIdCodeRequest | FinishOrderMoverController_AgOrderIdCodeRequest
  >,
) {
  try {
    return request.profileType === roles.CUSTOMER
      ? await admin.api.finishCustomerOrder(request.payload)
      : await admin.api.finishMoverOrder(request.payload);
  } catch (error) {
    throw transformError(error);
  }
}

export function signIn(request: SignInRequest): Promise<AuthTokensResponse> {
  return auth.api
    .signIn(auth.mappers.fromSignInRequest(request))
    .then((response) => auth.mappers.toAuthTokensResponse(response))
    .catch((error) => {
      throw transformError(error);
    });
}

export async function getOrdersForClosingDocs(request: UniqueEntity): Promise<string[]> {
  try {
    return admin.api.getOrdersForClosingDocs(request).then((response) => response.orders ?? []);
  } catch (error) {
    throw transformError(error);
  }
}

export async function getOrdersReadyForFinish(
  request: EntityWithProfileRequest<UniqueEntity>,
): Promise<AgOrderIdCodeListEntity[]> {
  try {
    const response =
      request.profileType === roles.CUSTOMER
        ? await admin.api.getCustomerOrdersReadyForFinish(request.payload)
        : await admin.api.getMoverOrdersReadyForFinish(request.payload);
    return response.entities ?? [];
  } catch (error) {
    throw transformError(error);
  }
}

export async function addCredit(
  request: AddCreditCustomerAdminController_CreditCustomerRequest,
): Promise<ResultResponse> {
  try {
    const response = await admin.api.addCredit(request);
    return admin.mappers.toResultResponse(response);
  } catch (error) {
    throw transformError(error);
  }
}

export async function syncTransportWithAti(request: { userId: string; value: string }) {
  try {
    return await admin.api.syncTransportWithAti(request);
  } catch (error) {
    throw transformError(error);
  }
}

export async function getUserIdByParams(request: {
  identifier: string;
  value: string;
}): Promise<GetUserIdByParamsController_UserIdResponse> {
  try {
    return await admin.api.getUserIdByParams(request);
  } catch (error) {
    throw transformError(error);
  }
}

export async function editUser(request: EditUserFormState): Promise<ResultResponse> {
  try {
    const response = await admin.api.editUser(admin.mappers.toEditUserRequest(request));
    return admin.mappers.toResultResponse(response);
  } catch (error) {
    throw transformError(error);
  }
}

export async function getUserRoles(request: { userId: string }): Promise<GetUserRolesController_UserRolesResponse> {
  try {
    return await admin.api.getUserRoles(request);
  } catch (error) {
    throw transformError(error);
  }
}

export async function getUsers(request: SearchUsersRequest): Promise<SearchUsersResponse> {
  try {
    const response = await admin.api.getUsers({
      sort: {
        sorting_field: request.sorting.sorting_field,
        sorting_order_by: request.sorting.sorting_order_by,
      },
      filter: {
        account_type: request.filter.account_type ?? null,
        verification_status: request.filter.verification_status ?? null,
      },
      pagination: request.pagination,
    });
    return admin.mappers.toSearchUsersResponse(response);
  } catch (error) {
    throw transformError(error);
  }
}

export async function calculateTariffFirstMile(
  request: AdminTariffsFirstMileController_PublicTariffCalculationFirstMileRequest,
): Promise<TariffOrderEntityResponse | undefined> {
  try {
    return await admin.api
      .calculateTariffFirstMile(request)
      .then((response) => toTariffOrderEntityResponse(response.tariff_order_entity));
  } catch (error) {
    throw transformError(error);
  }
}

export async function calculateTariffLastMile(
  request: AdminTariffsLastMileController_PublicTariffCalculationLastMileRequest,
): Promise<TariffOrderEntityResponse | undefined> {
  try {
    return await admin.api
      .calculateTariffLastMile(request)
      .then((response) => toTariffOrderEntityResponse(response.tariff_order_entity));
  } catch (error) {
    throw transformError(error);
  }
}

export async function calculateTariffEmptyMile(
  request: AdminTariffEmptyMileController_PublicTariffCalculationEmptyContainerRequest,
): Promise<TariffOrderEntityResponse | undefined> {
  try {
    return await admin.api
      .calculateTariffEmptyMile(request)
      .then((response) => toTariffOrderEntityResponse(response.tariff_order_entity));
  } catch (error) {
    throw transformError(error);
  }
}

export async function calculateTariff(request: CalculateTariffRequest): Promise<TariffOrderEntityResponse | undefined> {
  switch (request.deliveryType) {
    case FIRST_MILE_DELIVERY_TYPE:
      return calculateTariffFirstMile(admin.mappers.toFirstMileTariffRequest(request.payload));

    case LAST_MILE_DELIVERY_TYPE:
      return calculateTariffLastMile(admin.mappers.toLastMileTariffRequest(request.payload));

    default:
      return calculateTariffEmptyMile(admin.mappers.toEmptyMileTariffRequest(request.payload));
  }
}

export async function generateCustomerDocumentPackage(
  request: GenerateCustomerDocumentPackageController_AgGenerateCustomerDocumentPackageRequest,
): Promise<ResultResponse> {
  try {
    const response = await admin.api.generateCustomerDocumentPackage(request);
    return admin.mappers.toResultResponse(response);
  } catch (error) {
    throw transformError(error);
  }
}

export async function getOrdersWithoutContainers(): Promise<GetAllOrdersWithoutContainerNumberResponseEntity[]> {
  try {
    const response = await admin.api.getOrdersWithoutContainers();
    return response.entities ?? [];
  } catch (error) {
    throw transformError(error);
  }
}

export async function addContainerToOrder(
  request: AddContainerNumberToOrderController_AgAddContainerNumberToOrderRequest,
): Promise<ResultResponse> {
  try {
    const response = await admin.api.addContainerToOrder(request);
    return admin.mappers.toAddContainerToOrderResultResponse(response);
  } catch (error) {
    throw transformError(error);
  }
}

export async function getPromos(
  request: GetPromosRequest,
): Promise<GetPromoCodesCustomerAdminController_TariffCalculationPromoCodesCustomerAdminResponse> {
  try {
    return await admin.api.getPromos(request);
  } catch (error) {
    throw transformError(error);
  }
}

export async function getPromoInfo(): Promise<PromoCodeAdminEntity | undefined> {
  try {
    const response = await admin.api.getPromoInfo();
    return response.promo_code_info_output_entity?.promo_code_entity ?? undefined;
  } catch (error) {
    throw transformError(error);
  }
}

export async function createPromo(request: {
  payload: CreatePromoCodesAdminController_NewPromoCodesRequest;
  isSpecial: boolean;
}): Promise<ResultResponse> {
  const isSpecial = request.isSpecial;
  try {
    const response = isSpecial
      ? await admin.api.createSpecialPromo(request.payload)
      : await admin.api.createPromo(request.payload);
    return admin.mappers.toResultResponse(response);
  } catch (error) {
    throw transformError(error);
  }
}

export async function changePromo(
  request: UpdateStatusPromCodeAdminController_StatusPromoCodeRequest,
): Promise<ResultResponse> {
  try {
    const response = await admin.api.changePromo(request);
    return admin.mappers.toResultResponse(response);
  } catch (error) {
    throw transformError(error);
  }
}

export async function addRoles(request: ChangeUserRolesController_ChangeUserRolesRequest): Promise<ResultResponse> {
  try {
    const response = await admin.api.addRoles(request);
    return admin.mappers.toResultResponse(response);
  } catch (error) {
    throw transformError(error);
  }
}

export async function getUserInfo(
  request: GetUserInfoByAdminController_UserIdAdminRequest,
): Promise<GetUserInfoResponse> {
  try {
    const response = await admin.api.getUserInfo(request);
    return admin.mappers.toUserInfoResponse(response);
  } catch (error) {
    throw transformError(error);
  }
}

// SUPPORT

export async function getAdminTicketChatList(
  request: GetTicketChatRequest,
): Promise<GetPaginatedAdminTicketChatsListResponse> {
  try {
    const response = await admin.api.getAdminTicketChats({ pagination: request.pagination, filter: request.filter });

    return admin.mappers.toGetTicketChatListResponse(response);
  } catch (error) {
    throw transformError(error);
  }
}

export async function getAdminTicketChatDirectory(request: {
  user_id: string;
}): Promise<GetTicketChatDirectoryResponse> {
  try {
    const response = await admin.api.getAdminTicketChatDirectory(request);

    return tickets.mappers.toGetTicketChatDirectoryResponse(response);
  } catch (error) {
    throw transformError(error);
  }
}

export async function closeTicketChat(request: CloseTicketController_TicketCloseRequest): Promise<ResultResponse> {
  try {
    const response = await admin.api.closeTicketChat(request);

    return admin.mappers.toResultResponse(response);
  } catch (error) {
    throw transformError(error);
  }
}

export async function openTicketChat(
  request: OpenTicketAdminController_TicketAndTicketMessageAdminRequest,
): Promise<string> {
  try {
    const response = await admin.api.openAdminTicketChat(request);

    return response.ticket_id;
  } catch (error) {
    throw transformError(error);
  }
}

export async function moveTicketChatMessages(
  request: MoveTicketMessageController_TicketMessageMoveRequest,
): Promise<ResultResponse> {
  try {
    const response = await admin.api.moveTicketChatMessages(request);

    return admin.mappers.toResultResponse(response);
  } catch (error) {
    throw transformError(error);
  }
}

export async function deleteTicketChatMessages(
  request: DeleteTicketMessageAdminController_TicketMessageDeleteAdminRequest,
): Promise<ResultResponse> {
  try {
    const response = await admin.api.deleteTicketChatMessages(request);

    return admin.mappers.toResultResponse(response);
  } catch (error) {
    throw transformError(error);
  }
}

export async function getClosedTicketChatInfo(request: {
  ticketId: string;
}): Promise<GetClosedTicketInfoByAdminController_ClosedTicketInfoAdminResponse> {
  try {
    return await admin.api.getClosedTicketChatInfo(request);
  } catch (error) {
    throw transformError(error);
  }
}

export async function getTicketChatId(
  request: GetTicketIdAdminController_TicketTitleAndUserCodeRequest,
): Promise<GetTicketIdAdminController_TicketIdNullableResponse> {
  try {
    return await admin.api.getTicketChatId(request);
  } catch (error) {
    throw transformError(error);
  }
}

export async function getOrderList(
  request: GetOrderListAdminController_AgGetOrderListAdminRequest,
): Promise<GetOrderListAdminController_AgOrderListAdminResponse> {
  try {
    return await admin.api.getOrderList(request);
  } catch (error) {
    throw transformError(error);
  }
}

export async function addFileToExistingMoverPackage(
  request: AddFileToMoverDocumentPackageAdminController_AgAddFileToMoverDocumentPackageRequest,
): Promise<ResultResponse> {
  try {
    const response = await admin.api.addFileToExistingMoverPackage(request);
    return admin.mappers.toResultResponse(response);
  } catch (error) {
    throw transformError(error);
  }
}

export async function createNewMoverPackage(
  request: CreateNewMoverDocumentPackageAdminController_AgCreateNewMoverDocumentPackageRequest,
): Promise<ResultResponse> {
  try {
    const response = await admin.api.createNewMoverPackage(request);
    return admin.mappers.toResultResponse(response);
  } catch (error) {
    throw transformError(error);
  }
}

export async function getMoverDocsList(
  request: GetMoverDocsListRequest,
): Promise<GetMoverDocumentPackageFileEntityListAdminController_AgMoverDocumentPackageFileEntityListAdminResponse> {
  try {
    return await admin.api.getMoverDocsList(request);
  } catch (error) {
    throw transformError(error);
  }
}

export async function getMoverTransportDocsList(
  request: GetMoverTransportDocsListRequest,
): Promise<GetMoverTransportDocumentsListAdminController_AgMoverTransportDocumentsListAdminResponse> {
  try {
    return await admin.api.getMoverTransportDocsList(request);
  } catch (error) {
    throw transformError(error);
  }
}

export async function getMoverDocsPackages(
  request: GetMoverDocsPackageListRequest,
): Promise<GetMoverDocumentPackageEntityListAdminController_AgMoverDocumentPackageEntityListAdminResponse> {
  try {
    return await admin.api.getMoverDocsPackagesList(request);
  } catch (error) {
    throw transformError(error);
  }
}

export async function getCustomerDocsPackagesList(
  request: GetCustomerDocsPackageListRequest,
): Promise<GetCustomerDocumentPackageEntityListAdminController_AgCustomerDocumentPackageListAdminResponse> {
  try {
    return await admin.api.getCustomerDocsPackagesList(request);
  } catch (error) {
    throw transformError(error);
  }
}

export async function updateMoverDoc(
  request: UpdateMoverDocumentPackageFileAdminController_AgUpdateMoverDocumentPackageFileStatusRequest,
): Promise<ResultResponse> {
  try {
    const response = await admin.api.updateMoverDoc(request);
    return admin.mappers.toResultResponse(response);
  } catch (error) {
    throw transformError(error);
  }
}

export async function uploadMoverDoc(
  request: UploadMoverDocumentPackageFileAdminController_AgUploadMoverDocumentPackageFileAdminRequest,
): Promise<ResultResponse> {
  try {
    const response = await admin.api.uploadMoverDoc(request);
    return admin.mappers.toResultResponse(response);
  } catch (error) {
    throw transformError(error);
  }
}

export async function uploadBankDoc(
  request: AddBankStatementBillAdminController_BankStatementBillAGAdminRequest,
): Promise<ResultResponse> {
  try {
    const response = await admin.api.uploadBankDoc(request);
    return admin.mappers.toResultResponse(response);
  } catch (error) {
    throw transformError(error);
  }
}

export async function createMoverWithdrawRequest(
  request: WithdrawMoverBalanceAdminController_ApplicationWithdrawAdminRequest,
): Promise<ResultResponse> {
  try {
    const response = await admin.api.createMoverWithdrawRequest(request);
    return admin.mappers.toResultResponse(response);
  } catch (error) {
    throw transformError(error);
  }
}

export async function makeBankPayment(
  request: AddBankBillAdminController_BankPersonalBillAGRequest,
): Promise<ResultResponse> {
  try {
    const response = await admin.api.makeBankPayment(request);
    return admin.mappers.toResultResponse(response);
  } catch (error) {
    throw transformError(error);
  }
}

export async function getWithdrawList(
  request: GetAdminWithdrawListRequest,
): Promise<GetBillingApplicationWithdrawAdminController_BillingApplicationWithdrawAdminResponse> {
  try {
    return await admin.api.getWithdrawList(request);
  } catch (error) {
    throw transformError(error);
  }
}

export async function getCreditList(
  request: GetAdminCreditListRequest,
): Promise<GetBillingCreditIssuedCustomerAdminController_CreditIssuedCustomerResponse> {
  try {
    return await admin.api.getCreditList(request);
  } catch (error) {
    throw transformError(error);
  }
}

export async function getInvoiceList(
  request: GetAdminInvoiceListRequest,
): Promise<GetBillingInvoiceAdminController_BillingInvoiceAdminResponse> {
  try {
    return await admin.api.getInvoiceList(request);
  } catch (error) {
    throw transformError(error);
  }
}

export async function getOperationList(
  request: GetAdminOperationListRequest,
): Promise<GetBillingHistoryPersonalOperationAdminController_HistoryPersonalOperationAdminResponse> {
  try {
    return await admin.api.getOperationList(request);
  } catch (error) {
    throw transformError(error);
  }
}

export async function getPostpaymentList(
  request: GetAdminPostpaymentListRequest,
): Promise<GetBillingCreditOrderCustomerAdminController_CreditOrderCustomerAdminResponse> {
  try {
    return await admin.api.getPostpaymentList(request);
  } catch (error) {
    throw transformError(error);
  }
}

export async function getBankPaymentList(
  request: GetAdminBankPaymentListRequest,
): Promise<GetBillingBankBillAdminController_BankBillOperationAdminResponse> {
  try {
    return await admin.api.getBankPaymentList(request);
  } catch (error) {
    throw transformError(error);
  }
}

export async function setOriginalDocumentsSend(
  request: SetCustomerDocumentPackageOriginalDocumentsSentController_AgSetCustomerDocumentPackageOriginalDocumentsSentRequest,
): Promise<ResultResponse> {
  try {
    const response = await admin.api.setOriginalDocumentsSend(request);
    return admin.mappers.toResultResponse(response);
  } catch (error) {
    throw transformError(error);
  }
}

export async function getCustomerStatusChain(request: { orderId: string }): Promise<AgOrderStatusEntity[]> {
  try {
    const response = await admin.api.getCustomerStatusChain(request);
    return response.status_chain ?? [];
  } catch (error) {
    throw transformError(error);
  }
}

export async function getMoverStatusChain(request: { orderId: string }): Promise<AgOrderStatusEntity[]> {
  try {
    const response = await admin.api.getMoverStatusChain(request);
    return response.status_chain ?? [];
  } catch (error) {
    throw transformError(error);
  }
}

export async function addDocToOrder(
  request: AddDocumentToOrderController_AgAddDocumentToOrderRequest,
): Promise<ResultResponse> {
  try {
    const response = await admin.api.addDocToOrder(request);
    return admin.mappers.toResultResponse(response);
  } catch (error) {
    throw transformError(error);
  }
}

export async function getCompletedWorkAct(request: { user_uuid: string | null }): Promise<CompletedWorkActEntity[]> {
  try {
    const response = await admin.api.getCompletedWorkAct(request);
    return response.completed_work_act_entities ?? [];
  } catch (error) {
    throw transformError(error);
  }
}

export async function changeTransportOwner(
  request: ChangeTransportOwnerController_ChangeTransportOwnerRequest,
): Promise<ResultResponse> {
  try {
    const response = await admin.api.changeTransportOwner(request);
    return admin.mappers.toResultResponse(response);
  } catch (error) {
    throw transformError(error);
  }
}

export async function setTransportOriginalDocsReceived(
  request: SetMoverTransportDocumentOriginalReceivedController_AgMoverTransportDocumentOriginalReceivedRequest,
): Promise<ResultResponse> {
  try {
    const response = await admin.api.setTransportOriginalDocsReceived(request);
    return admin.mappers.toResultResponse(response);
  } catch (error) {
    throw transformError(error);
  }
}

export async function setAccountancyOriginalDocsReceived(
  request: SetMoverDocumentPackageFileOriginalReceivedController_AgMoverDocumentPackageFileOriginalReceivedRequest,
): Promise<ResultResponse> {
  try {
    const response = await admin.api.setAccountancyOriginalDocsReceived(request);
    return admin.mappers.toResultResponse(response);
  } catch (error) {
    throw transformError(error);
  }
}

export async function getDriverList(
  request: GetDriverListController_DriverListRequest,
): Promise<GetDriverListController_DriverListResponse> {
  try {
    return await admin.api.getDriverList(request);
  } catch (error) {
    throw transformError(error);
  }
}

export async function getTruckList(
  request: GetTruckListController_TruckAndSemiTrailerListRequest,
): Promise<GetTruckListController_TruckListResponse> {
  try {
    return await admin.api.getTruckList(request);
  } catch (error) {
    throw transformError(error);
  }
}

export async function getSemitrailerList(
  request: GetSemiTrailerListController_TruckAndSemiTrailerListRequest,
): Promise<GetSemiTrailerListController_SemiTrailerListResponse> {
  try {
    return await admin.api.getSemitrailerList(request);
  } catch (error) {
    throw transformError(error);
  }
}

export async function cancelOrder(request: CancelOrderAdminController_AgOrderCancelRequest): Promise<ResultResponse> {
  try {
    const response = await admin.api.cancelOrder(request);
    return admin.mappers.toResultResponse(response);
  } catch (error) {
    throw transformError(error);
  }
}

export async function getAdminMenu(): Promise<AdminMenuItem[]> {
  try {
    return await admin.api.getAdminMenu();
  } catch (error) {
    throw transformError(error);
  }
}

export async function changeFinishDate(
  request: ChangeContainerDeliveredAtController_AgChangeContainerDeliveredAtRequest,
): Promise<ResultResponse> {
  try {
    const response = await admin.api.changeFinishDate(request);
    return admin.mappers.toResultResponse(response);
  } catch (error) {
    throw transformError(error);
  }
}

export async function getUserDictionary(
  request: {
    account_type: "customer" | "mover";
  } | null,
): Promise<UserShortInfoEntity[]> {
  try {
    const response = await admin.api.getUserDictionary(request);
    return response.user_short_info_entities ?? [];
  } catch (error) {
    throw transformError(error);
  }
}

export async function getDriverWorkflowList(request: { driver_uniq_code: string }): Promise<WorkflowCheckEntity[]> {
  try {
    const response = await admin.api.getDriverWorkflowList(request);
    return response.workflow_check_entities ?? [];
  } catch (error) {
    throw transformError(error);
  }
}

export async function getTruckWorkflowList(request: { truck_uniq_code: string }): Promise<WorkflowCheckEntity[]> {
  try {
    const response = await admin.api.getTruckWorkflowList(request);
    return response.workflow_check_entities ?? [];
  } catch (error) {
    throw transformError(error);
  }
}

export async function getSemitrailerWorkflowList(request: {
  semi_trailer_uniq_code: string;
}): Promise<WorkflowCheckEntity[]> {
  try {
    const response = await admin.api.getSemitrailerWorkflowList(request);
    return response.workflow_check_entities ?? [];
  } catch (error) {
    throw transformError(error);
  }
}

export async function getUserWorkflowList(request: { user_id: string }): Promise<WorkflowCheckEntity[]> {
  try {
    const response = await admin.api.getUserWorkflowList(request);
    return response.workflow_check_entities ?? [];
  } catch (error) {
    throw transformError(error);
  }
}

export async function getWorkflowList(request: {
  entity: "truck" | "semitrailer" | "driver" | "user";
  payload: {
    id: string;
  };
}): Promise<WorkflowCheckEntity[]> {
  switch (request.entity) {
    case "driver":
      return getDriverWorkflowList({
        driver_uniq_code: request.payload.id,
      });

    case "semitrailer":
      return getSemitrailerWorkflowList({
        semi_trailer_uniq_code: request.payload.id,
      });

    case "truck":
      return getTruckWorkflowList({
        truck_uniq_code: request.payload.id,
      });

    default:
      return getUserWorkflowList({
        user_id: request.payload.id,
      });
  }
}

export async function getAllWorkflowList(
  request: GetAdminAllManualCheckListRequest,
): Promise<GetAllManualChecksController_WorkflowAllManualChecksResponse> {
  try {
    return await admin.api.getAllWorkflowCheckList(request);
  } catch (error) {
    throw transformError(error);
  }
}

export async function getBriefOrders(
  request: GetBriefOrderListRequest,
): Promise<AgGetOrderBriefInfoListAdminItemEntity[]> {
  try {
    const response = await admin.api.getBriefOrders(request);
    return response.entities ?? [];
  } catch (error) {
    throw transformError(error);
  }
}

export async function getBriefDocPackages(request: GetBriefDocPackageListRequest): Promise<
  AgGetMoverDocumentPackageBriefInfoListAdminItemEntity &
    {
      label: string;
      value: string;
    }[]
> {
  try {
    const response = await admin.api.getBriefDocPackages(request);
    return response.entities
      ? response.entities.map((dp) => {
          return {
            ...dp,
            label: dp.package_id_code ?? "",
            value: dp.package_id ?? "",
          };
        })
      : [];
  } catch (error) {
    throw transformError(error);
  }
}

export async function getCheckUserDetails(request: { check_uuid: string }): Promise<GetCheckDetailsResponse> {
  try {
    const response = await admin.api.getCheckUserDetails(request);
    return {
      profile_entity: response.profile_entity ?? undefined,
      document_file_entity: response.document_file_entity ?? undefined,
      company_entity: response.company_entity ?? undefined,
    };
  } catch (error) {
    throw transformError(error);
  }
}

export async function getCheckDriverDetails(request: { check_uuid: string }): Promise<GetCheckDetailsResponse> {
  try {
    const response = await admin.api.getCheckDriverDetails(request);
    return {
      driver_entity: response.driver_entity ?? undefined,
      document_file_entity: response.document_file_entity ?? undefined,
      company_entity: response.company_entity ?? undefined,
    };
  } catch (error) {
    throw transformError(error);
  }
}

export async function getCheckSemiTrailerDetails(request: { check_uuid: string }): Promise<GetCheckDetailsResponse> {
  try {
    const response = await admin.api.getCheckSemiTrailerDetails(request);
    return {
      semi_trailer_entity: response.semi_trailer_entity ?? undefined,
      document_file_entity: response.document_file_entity ?? undefined,
      company_entity: response.company_entity ?? undefined,
      check_info_entity: response.check_info_entity,
    };
  } catch (error) {
    throw transformError(error);
  }
}

export async function getCheckTruckDetails(request: { check_uuid: string }): Promise<GetCheckDetailsResponse> {
  try {
    const response = await admin.api.getCheckTruckDetails(request);
    return {
      truck_entity: response.truck_entity ?? undefined,
      document_file_entity: response.document_file_entity ?? undefined,
      company_entity: response.company_entity ?? undefined,
      check_info_entity: response.check_info_entity,
    };
  } catch (error) {
    throw transformError(error);
  }
}

export async function getCheckDetails(request: {
  check_uuid: string;
  entity: "user" | "driver" | "semitrailer" | "truck";
}): Promise<GetCheckDetailsResponse> {
  switch (request.entity) {
    case "driver":
      return getCheckDriverDetails({
        check_uuid: request.check_uuid,
      });

    case "semitrailer":
      return getCheckSemiTrailerDetails({
        check_uuid: request.check_uuid,
      });

    case "truck":
      return getCheckTruckDetails({
        check_uuid: request.check_uuid,
      });

    default:
      return getCheckUserDetails({
        check_uuid: request.check_uuid,
      });
  }
}

export async function closePostpayment(
  request: AdvancedCustomerCreditRepaymentAdminController_CustomerCreditRepaymentAdminAGRequest,
): Promise<ResultResponse> {
  try {
    const response = await admin.api.closePostpayment(request);
    return admin.mappers.toResultResponse(response);
  } catch (error) {
    throw transformError(error);
  }
}

export async function getUserAuthTokens(request: {
  user_id: string;
}): Promise<UserSpoofingController_SecurityTokenResponse> {
  try {
    return await admin.api.getUserAuthTokens(request);
  } catch (error) {
    throw transformError(error);
  }
}

export async function getDigitizationList(
  request: GetAdminDigitizationListRequest,
): Promise<GetDigitizationsListAdminController_AgGetDigitizationsListAdminResponse> {
  try {
    return await admin.api.getDigitizationList(request);
  } catch (error) {
    throw transformError(error);
  }
}

export async function declineDocument(
  request: DeclineDocumentDigitizationAdminController_AgDeclineDigitizationAdminRequest,
): Promise<ResultResponse> {
  try {
    const response = await admin.api.declineDocument(request);
    return admin.mappers.toResultResponse(response);
  } catch (error) {
    throw transformError(error);
  }
}

export async function getCompanyList(
  request: Get1CCompanyListRequest,
): Promise<GetCompanyWith1CInfoListAdminController_AgCompanyWith1CInfoListAdminResponse> {
  try {
    return await admin.api.getCompanyList(request);
  } catch (error) {
    throw transformError(error);
  }
}

export async function changeContractorInfo(
  request: UpdateContractor1CInfoAdminController_AgContractor1CInfoAdminRequest,
): Promise<ResultResponse> {
  try {
    const response = await admin.api.changeContractorInfo(request);
    return admin.mappers.toResultResponse(response);
  } catch (error) {
    throw transformError(error);
  }
}

export async function getWidgets(request: GetWidgetFilterRequest): Promise<WidgetEntity> {
  try {
    const response = await admin.api.getWidgets(request);
    return {
      title: WidgetDictionary[request.aggregate_filter].title,
      id: request.aggregate_filter,
      description: WidgetDictionary[request.aggregate_filter].description,
      count: response.count_orders,
      modal: WidgetDictionary[request.aggregate_filter].modal,
      role: WidgetDictionary[request.aggregate_filter].role,
    };
  } catch (error) {
    throw transformError(error);
  }
}

export async function recalculateTariff(
  request: AdminRecalculationTariffController_RecalculationTariffAdminRequest,
): Promise<RecalculateTariffOrderEntityResponse | undefined> {
  try {
    return await admin.api
      .recalculateTariff(request)
      .then((response) => toRecalculateTariffOrderEntityResponse(response.tariff_order_entity));
  } catch (error) {
    throw transformError(error);
  }
}

export async function changeTariff(
  request: UpdateTariffAdminController_AgRecalculationOrderTariffAdminRequest,
): Promise<ResultResponse> {
  try {
    const response = await admin.api.changeTariff(request);
    return admin.mappers.toResultResponse(response);
  } catch (error) {
    throw transformError(error);
  }
}

type CompanyResponseType = {
  name: string;
  inn: string;
  prefixes: string[];
};

export async function getWidgetCompany(request: GetWidgetCompanyFilterRequest): Promise<CompanyResponseType[]> {
  try {
    const response = await admin.api.getWidgetCompany(request);

    return response.list_company ? toCompanyResponseType(response.list_company) : [];
  } catch (error) {
    throw transformError(error);
  }
}

function toCompanyResponseType(input: ListCompanyWidgetOrdersOut[]): CompanyResponseType[] {
  if (!input.length) return [];
  const map = new Map();
  let companies: CompanyResponseType[] = [];
  input.map((item) => {
    if (map.has(item.inn_company)) {
      map.set(item.inn_company, [...map.get(item.inn_company), item.uniq_code]);
    } else {
      map.set(item.inn_company, [item.uniq_code]);
    }
  });
  map.forEach((v, k) => {
    const set = new Set(v as string[]);
    const company = input.find((c) => c.inn_company === k);

    companies.push({
      name: company ? company.name_company ?? "" : "",
      inn: k,
      // @ts-ignore
      prefixes: [...set],
    });
  });

  return companies;
}
