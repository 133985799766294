import Modal, { ModalProps } from "@/components/Modal2/Modal";
import clsx from "clsx";
import React from "react";
import { useQuery } from "react-query";
import { GET_WIDGET_COMPANY_QUERY } from "@/data/query-keys";
import { AppError } from "@/domain";
import { adminService } from "@/services";
import { GetWidgetCompanyFilterType } from "@/domain/admin";
import LoadingState from "@/dashboard/layout/loading-state";
import ErrorState from "@/dashboard/layout/error-state";

interface CompanyListModalProps extends ModalProps {
  filter: GetWidgetCompanyFilterType | null;
}

const CompanyListModal: React.FC<CompanyListModalProps> = (props) => {
  const { children, onClose, filter } = props;
  const { data, isLoading, error, refetch } = useQuery(
    [GET_WIDGET_COMPANY_QUERY, filter],
    () =>
      adminService.getWidgetCompany({
        aggregate_filter: filter!,
      }),
    {
      enabled: !!filter,
      retry: false,
      refetchOnWindowFocus: false,
      refetchOnMount: true,
      onError: (err: AppError) => {},
      onSuccess: () => {},
    },
  );

  return (
    <Modal {...props} className={clsx("!relative h-fit !min-w-fit !overflow-y-hidden !p-0", {})}>
      <div className="absolute right-5 top-5 cursor-pointer" onClick={onClose}>
        <CloseIcon />
      </div>
      <div className="flex max-h-[900px] min-w-[600px] max-w-[1600px] flex-col overflow-y-auto rounded-xl border border-divider p-6 px-10">
        <>{isLoading && <LoadingState />}</>
        <>{error && <ErrorState error={error} onRetry={refetch} />}</>
        <>
          {data && data.length > 0 && (
            <div className="flex flex-col gap-10">
              {data.map((c) => (
                <div className="flex flex-col gap-1">
                  <p className="text-textBodySemi">
                    {c.name + " "}({c.inn})
                  </p>
                  <div>
                    {c.prefixes.map((p) => (
                      <p>{p}</p>
                    ))}
                  </div>
                </div>
              ))}
            </div>
          )}
        </>
        <>{data && data.length === 0 && <div>Нет данных</div>}</>
      </div>
    </Modal>
  );
};

export default CompanyListModal;

const CloseIcon = () => (
  <svg
    width="25"
    height="24"
    viewBox="0 0 25 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className="stroke-disabled hover:stroke-secondaryText active:stroke-primaryText"
  >
    <path d="M18.666 6L6.66602 18" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M6.66602 6L18.666 18" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
);
