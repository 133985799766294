import {
  fetchAddressGeoLocationSuggestions,
  fetchAddressSuggestions,
  fetchBankSuggestions,
  fetchFioSuggestions,
  fetchFmsUnitsSuggestions,
  fetchPartySuggestions,
} from "@/api/dadata-api";
import { cleanAddress, getGeoPosition } from "./misc";
import { WarehouseAddressOption } from "@/dashboard/create-order/types";

export const getAddressSuggestions = (query: string) =>
  fetchAddressSuggestions({ query }).then((s) => s.map((i) => ({ label: i.value, value: i.value })));

export const getAddressGeoLocationSuggestions = (query: string) =>
  fetchAddressGeoLocationSuggestions({ query }).then((s) =>
    s.map((i) => ({
      label: i.value,
      value: i.value,
      lat: i.data.geo_lat,
      lon: i.data.geo_lon,
    })),
  );

export const getWarehouseAddressGeoLocationSuggestions = (query: string) =>
  fetchAddressGeoLocationSuggestions({ query }).then((s) => {
    if (s.length === 0)
      return [
        {
          label: query,
          value: query,
          lat: null,
          lon: null,
          isSearchable: false,
        },
      ];
    if (!!s.find((i) => i.value === query)) {
      return s.map((i) => ({
        label: i.value,
        value: i.value,
        lat: i.data.geo_lat,
        lon: i.data.geo_lon,
        isSearchable: true,
      }));
    }
    return [
      ...s.map((i) => ({
        label: i.value,
        value: i.value,
        lat: i.data.geo_lat,
        lon: i.data.geo_lon,
        isSearchable: true,
      })),
      {
        label: query,
        value: query,
        lat: null,
        lon: null,
        isSearchable: false,
      },
    ];
  });

export const getFmsUnitSuggestions = (query: string) =>
  fetchFmsUnitsSuggestions(query).then((s) => s.map((i) => ({ label: i.value, value: i.value })));

export const getBankSuggestions = (query: string) =>
  fetchBankSuggestions(query).then((s) =>
    s.map((i) => ({
      label: i.value,
      value: i.data.bic,
      corrAccount: i.data.correspondent_account,
      bic: i.data.bic,
      bill: "",
      name: i.value,
    })),
  );

export const getSurnameSuggestions = (query: string) =>
  fetchFioSuggestions({ query, part: "SURNAME" }).then((data) => data.map((i) => ({ value: i.value, label: i.value })));

export const getNameSuggestions = (query: string) =>
  fetchFioSuggestions({ query, part: "NAME" }).then((data) => data.map((i) => ({ value: i.value, label: i.value })));

export const getPatronymicSuggestions = (query: string) =>
  fetchFioSuggestions({ query, part: "PATRONYMIC" }).then((data) =>
    data.map((i) => ({ value: i.value, label: i.value })),
  );

export const getPartySuggestions = (query: string) =>
  fetchPartySuggestions({
    query,
  }).then((s) => s.map((i) => ({ label: i.value, value: i.data.inn })));

export const getCleanedAddress = (query: string) =>
  cleanAddress({
    address: query,
  }).then((s) => ({
    label: s ? s.address : "",
    value: s ? s.address : "",
    lat: s ? s.latitude : null,
    lon: s ? s.longitude : null,
  }));

export const getCleanedAddressGeoPosition = async (query: string): Promise<WarehouseAddressOption> => {
  const cleanedAddressResponse = await cleanAddress({
    address: query,
  });
  if (!cleanedAddressResponse) return null;
  const cleanedAddress = cleanedAddressResponse.address;
  if (!cleanedAddress) return null;
  const geoPosition = await getGeoPosition({
    address_geo_input_entity: {
      address: cleanedAddress,
    },
  });
  if (!geoPosition) return null;
  return {
    label: cleanedAddress,
    value: cleanedAddress,
    lat: geoPosition.geo_lat.toString(),
    lon: geoPosition.geo_lon.toString(),
    isSearchable: true,
  };
};
