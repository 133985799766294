import { clientDefault, clientWithAuth } from "../client";
import {
  AddBankBillAdminController_BankPersonalBillAGRequest,
  AddBankBillAdminController_ResultBoolResponse,
  AddBankStatementBillAdminController_BankStatementBillAGAdminRequest,
  AddBankStatementBillAdminController_ResultBoolResponse,
  AddContainerNumberToOrderController_AddContainerNumberToOrderResponse,
  AddContainerNumberToOrderController_AgAddContainerNumberToOrderRequest,
  AddCreditCustomerAdminController_CreditCustomerRequest,
  AddCreditCustomerAdminController_CreditCustomerResponse,
  AddDocumentToOrderController_AgAddDocumentToOrderRequest,
  AddDocumentToOrderController_ResultBoolResponse,
  AddFileToMoverDocumentPackageAdminController_AgAddFileToMoverDocumentPackageRequest,
  AddFileToMoverDocumentPackageAdminController_ResultBoolResponse,
  AdminRecalculationTariffController_AdminTariffCalculationResponse,
  AdminRecalculationTariffController_RecalculationTariffAdminRequest,
  AdminTariffEmptyMileController_AdminTariffCalculationResponse,
  AdminTariffEmptyMileController_PublicTariffCalculationEmptyContainerRequest,
  AdminTariffsFirstMileController_AdminTariffCalculationResponse,
  AdminTariffsFirstMileController_PublicTariffCalculationFirstMileRequest,
  AdminTariffsLastMileController_AdminTariffCalculationResponse,
  AdminTariffsLastMileController_PublicTariffCalculationLastMileRequest,
  AdvancedCustomerCreditRepaymentAdminController_CustomerCreditRepaymentAdminAGRequest,
  AdvancedCustomerCreditRepaymentAdminController_CustomerCreditRepaymentAdminResponse,
  CancelOrderAdminController_AgOrderCancelRequest,
  CancelOrderAdminController_ResultBoolResponse,
  ChangeContainerDeliveredAtController_AgChangeContainerDeliveredAtRequest,
  ChangeContainerDeliveredAtController_ResultBoolResponse,
  ChangeCustomerTicketPinnedStatusController_ResultBoolResponse,
  ChangeTicketPinnedStatusAdminController_NewTicketPinnedStatusRequest,
  ChangeTicketPinnedStatusAdminController_ResultBoolResponse,
  ChangeTransportOwnerController_ChangeTransportOwnerRequest,
  ChangeTransportOwnerController_ResultBoolResponse,
  ChangeUserRolesController_ChangeUserRolesRequest,
  ChangeUserRolesController_ResultBoolResponse,
  CloseTicketController_ResultBoolResponse,
  CloseTicketController_TicketCloseRequest,
  CreateNewMoverDocumentPackageAdminController_AgCreateNewMoverDocumentPackageRequest,
  CreateNewMoverDocumentPackageAdminController_ResultBoolResponse,
  CreatePromoCodesAdminController_NewPromoCodesRequest,
  CreatePromoCodesAdminController_ResultBoolResponse,
  CreatePromoCodesSpecialAdminController_NewPromoCodesRequest,
  CreatePromoCodesSpecialAdminController_ResultBoolResponse,
  CreateTn2022ManuallyController_AgCreateTn2022Request,
  CreateTn2022ManuallyController_AgCreateTn2022Response,
  DeclineDocumentDigitizationAdminController_AgDeclineDigitizationAdminRequest,
  DeclineDocumentDigitizationAdminController_ResultBoolResponse,
  DeleteTicketMessageAdminController_ResultBoolResponse,
  DeleteTicketMessageAdminController_TicketMessageDeleteAdminRequest,
  EditUserProfileController_EditUserProfileRequest,
  EditUserProfileController_ResultBoolResponse,
  FinishOrderCustomerController_AgOrderIdCodeRequest,
  FinishOrderCustomerController_ResultBoolResponse,
  FinishOrderMoverController_AgOrderIdCodeRequest,
  FinishOrderMoverController_ResultBoolResponse,
  GenerateClosingDocumentsForOrders_AgGenerateClosingDocumentsForOrdersRequest,
  GenerateClosingDocumentsForOrders_ResultBoolResponse,
  GenerateCustomerDocumentPackageController_AgGenerateCustomerDocumentPackageRequest,
  GenerateCustomerDocumentPackageController_ResultBoolResponse,
  GetAllManualChecksController_WorkflowAllManualChecksResponse,
  GetAllManualDigitizationsController_GetAllDigitizationsInStatusResponse,
  GetAllOrdersAvailableForClosingDocumentsGeneration_AgGetAllOrdersAvailableForClosingDocumentsGenerationResponse,
  GetAllOrdersReadyToBeClosedCustomerController_AgOrderIdCodeListResponse,
  GetAllOrdersReadyToBeClosedMoverController_AgOrderIdCodeListResponse,
  GetAllOrdersWithoutContainerNumberController_GetAllOrdersWithoutContainerNumberResponse,
  GetBillingApplicationWithdrawAdminController_BillingApplicationWithdrawAdminResponse,
  GetBillingBankBillAdminController_BankBillOperationAdminResponse,
  GetBillingCompletedWorkActAdminController_BillingCompletedWorkActAdminResponse,
  GetBillingCreditIssuedCustomerAdminController_CreditIssuedCustomerResponse,
  GetBillingCreditOrderCustomerAdminController_CreditOrderCustomerAdminResponse,
  GetBillingHistoryPersonalOperationAdminController_HistoryPersonalOperationAdminResponse,
  GetBillingInvoiceAdminController_BillingInvoiceAdminResponse,
  GetCheckDetailsDriverController_WorkFlowCheckDetailsDriverResponse,
  GetCheckDetailsSemiTrailerController_WorkFlowCheckDetailsSemiTrailerResponse,
  GetCheckDetailsTruckController_WorkFlowCheckDetailsTruckResponse,
  GetCheckDetailsUserController_WorkFlowCheckDetailsUserResponse,
  GetClosedTicketInfoByAdminController_ClosedTicketInfoAdminResponse,
  GetCompanyWith1CInfoListAdminController_AgCompanyWith1CInfoListAdminResponse,
  GetCustomerDocumentPackageEntityListAdminController_AgCustomerDocumentPackageListAdminResponse,
  GetDigitizationsListAdminController_AgGetDigitizationsListAdminResponse,
  GetDriverListController_DriverListRequest,
  GetDriverListController_DriverListResponse,
  GetDriverWorkFlowController_TransportWorkFlowResponse,
  GetListCompanyWidgetOrderAGFilter_ListCompanyWidgetOrdersResponse,
  GetMovedFromOtherTicketMessageInfoAdminController_MovedFromOtherTicketMessageInfoResponse,
  GetMovedToOtherTicketMessageInfoAdminController_MovedToOtherTicketMessageInfoResponse,
  GetMoverDocumentPackageBriefInfoListAdminController_AgGetMoverDocumentPackageBriefInfoListAdminResponse,
  GetMoverDocumentPackageEntityListAdminController_AgMoverDocumentPackageEntityListAdminResponse,
  GetMoverDocumentPackageFileEntityListAdminController_AgMoverDocumentPackageFileEntityListAdminResponse,
  GetMoverTransportDocumentsListAdminController_AgMoverTransportDocumentsListAdminResponse,
  GetOrderBriefInfoListAdminController_AgGetOrderBriefInfoListAdminResponse,
  GetOrderCustomerStatusChainAdminController_AgOrderCustomerStatusChainAdminResponse,
  GetOrderListAdminController_AgGetOrderListAdminRequest,
  GetOrderListAdminController_AgOrderListAdminResponse,
  GetOrderMoverStatusChainAdminController_AgOrderMoverStatusChainAdminResponse,
  GetPromoCodeInfoAdminController_TariffCalculationPromoCodeInfoAdminResponse,
  GetPromoCodesCustomerAdminController_TariffCalculationPromoCodesCustomerAdminResponse,
  GetSemiTrailerListController_SemiTrailerListResponse,
  GetSemiTrailerListController_TruckAndSemiTrailerListRequest,
  GetSemiTrailerWorkFlowController_TransportWorkFlowResponse,
  GetShortTicketsAdminController_ShortTicketListResponse,
  GetTicketIdAdminController_TicketIdNullableResponse,
  GetTicketIdAdminController_TicketTitleAndUserCodeRequest,
  GetTicketListAdminController_TicketListAdminRequest,
  GetTicketListAdminController_TicketListAdminResponse,
  GetTicketMessageListAdminController_TicketMessageListAdminResponse,
  GetTruckListController_TruckAndSemiTrailerListRequest,
  GetTruckListController_TruckListResponse,
  GetTruckWorkFlowController_TransportWorkFlowResponse,
  GetUnreadTicketMessageCountAdminController_UnreadTicketMessageCountResponse,
  GetUserIdByParamsController_UserIdResponse,
  GetUserInfoByAdminController_UserIdAdminRequest,
  GetUserInfoByAdminController_UserInfoAdminResponse,
  GetUserListLightController_AdminUserListLightResponse,
  GetUserRolesController_UserRolesResponse,
  GetUsersListController_AgUserListResponse,
  GetUsersListController_AgUsersListRequest,
  GetUserWorkFlowController_WorkFlowResponse,
  GetWidgetOrderAggregateFilterController_WidgetOrdersResponse,
  ManualDocumentDigitizationController_DigitizeDocumentManuallyResponse,
  ManualSyncAtiController_ResultBoolResponse,
  MarkMessagesAsReadAdminController_ResultBoolResponse,
  MarkMoverMessagesAsReadController_MessagesMarkAsReadRequest,
  MarkMoverMessagesAsReadController_ResultBoolResponse,
  MoveTicketMessageController_ResultBoolResponse,
  MoveTicketMessageController_TicketMessageMoveRequest,
  OpenTicketAdminController_TicketAndTicketMessageAdminRequest,
  OpenTicketAdminController_TicketIdResponse,
  SendManualCheckController_ManualCheckRequest,
  SendManualCheckController_ResultBoolResponse,
  SendTicketMessageAdminController_NewTicketMessageAdminRequest,
  SendTicketMessageAdminController_TicketMessageIdAdminResponse,
  SetCustomerDocumentPackageOriginalDocumentsSentController_AgSetCustomerDocumentPackageOriginalDocumentsSentRequest,
  SetCustomerDocumentPackageOriginalDocumentsSentController_ResultBoolResponse,
  SetMoverDocumentPackageFileOriginalReceivedController_AgMoverDocumentPackageFileOriginalReceivedRequest,
  SetMoverDocumentPackageFileOriginalReceivedController_ResultBoolResponse,
  SetMoverTransportDocumentOriginalReceivedController_AgMoverTransportDocumentOriginalReceivedRequest,
  SetMoverTransportDocumentOriginalReceivedController_ResultBoolResponse,
  SetOrderStatusController_AgSetOrderStatusWithParamsRequest,
  SetOrderStatusController_ResultBoolResponse,
  UpdateContractor1CInfoAdminController_AgContractor1CInfoAdminRequest,
  UpdateContractor1CInfoAdminController_ResultBoolResponse,
  UpdateMoverDocumentPackageFileAdminController_AgUpdateMoverDocumentPackageFileStatusRequest,
  UpdateMoverDocumentPackageFileAdminController_ResultBoolResponse,
  UpdateStatusPromCodeAdminController_ResultBoolResponse,
  UpdateStatusPromCodeAdminController_StatusPromoCodeRequest,
  UpdateTariffAdminController_AgRecalculationOrderTariffAdminRequest,
  UpdateTariffAdminController_ResultBoolResponse,
  UploadMoverDocumentPackageFileAdminController_AgUploadMoverDocumentPackageFileAdminRequest,
  UploadMoverDocumentPackageFileAdminController_ResultBoolResponse,
  UserSpoofingController_SecurityTokenResponse,
  WithdrawMoverBalanceAdminController_ApplicationWithdrawAdminRequest,
  WithdrawMoverBalanceAdminController_ResultBoolResponse,
} from "../codegen";
import {
  ADD_CONTAINER_TO_ORDER,
  ADD_CREDIT,
  ADD_DOC_TO_ORDER,
  ADD_FILE_TO_EXISTING_MOVER_PACKAGE,
  ADD_ROLES,
  ADMIN_CHANGE_TICKET_PINNED_STATUS,
  ADMIN_TICKET_CHAT_DIRECTORY,
  ADMIN_TICKET_CHAT_LIST,
  ADMIN_TICKET_CHAT_MESSAGE_LIST,
  ADMIN_UNREAD_MESSAGE_COUNT,
  CALCULATE_TARIFF_EMPTY_MILE_ADMIN,
  CALCULATE_TARIFF_FIRST_MILE_ADMIN,
  CALCULATE_TARIFF_LAST_MILE_ADMIN,
  CANCEL_ADMIN_ORDER,
  CHANGE_CONTRACTOR_INFO,
  CHANGE_FINISH_DATE,
  CHANGE_PROMO_STATUS,
  CHANGE_TARIFF_ADMIN,
  CHANGE_TRANSPORT_OWNER,
  CHECK_VERIFICATION_MANUALLY,
  CLOSE_POSTPAYMENT,
  CLOSE_TICKET_CHAT,
  CREATE_MOVER_WITHDRAW_REQUEST,
  CREATE_NEW_MOVER_PACKAGE,
  CREATE_PROMO,
  CREATE_SPECIAL_PROMO,
  CREATE_TN_2022_MANUALLY,
  DECLINE_DOCUMENT,
  DELETE_TICKET_CHAT_MESSAGES,
  EDIT_USER,
  FINISH_ORDER_FOR_CUSTOMER,
  FINISH_ORDER_FOR_MOVER,
  FIXATE_SEND_ORIGINALS,
  GENERATE_CLOSING_DOCUMENTS,
  GENERATE_CUSTOMER_DOCUMENT_PACKAGE,
  GET_ADMIN_MENU,
  GET_ALL_MANUAL_DIGITIZATIONS,
  GET_ALL_VERIFICATIONS,
  GET_ALL_WORKFLOW_CHECK_LIST,
  GET_BANK_PAYMENT_LIST,
  GET_BRIEF_DOC_PACKAGE_LIST,
  GET_BRIEF_ORDER_LIST,
  GET_CHECK_DRIVER_DETAILS,
  GET_CHECK_SEMI_TRAILER_DETAILS,
  GET_CHECK_TRUCK_DETAILS,
  GET_CHECK_USER_DETAILS,
  GET_CLOSED_TICKET_INFO,
  GET_COMPANY_LIST,
  GET_COMPLETED_WORK_ACT,
  GET_CREDIT_LIST,
  GET_CUSTOMER_DOCS_PACKAGE,
  GET_CUSTOMER_ORDER_STATUS_CHAIN,
  GET_CUSTOMER_ORDERS_READY_FOR_FINISH,
  GET_DIGITIZATION_LIST,
  GET_DRIVER_LIST,
  GET_DRIVER_WORKFLOW_LIST,
  GET_INVOICE_LIST,
  GET_MOVED_FROM_MESSAGE_INFO,
  GET_MOVED_MESSAGE_INFO,
  GET_MOVER_DOCS_LIST,
  GET_MOVER_DOCS_PACKAGE,
  GET_MOVER_ORDER_STATUS_CHAIN,
  GET_MOVER_ORDERS_READY_FOR_FINISH,
  GET_MOVER_TRANSPORT_DOCS_LIST,
  GET_OPERATION_LIST,
  GET_ORDER_LIST,
  GET_ORDERS_WITHOUT_CONTAINERS,
  GET_POSTPAYMENT_LIST,
  GET_PROMO_INFO,
  GET_PROMOS,
  GET_SEMITRAILER_LIST,
  GET_SEMITRAILER_WORKFLOW_LIST,
  GET_TICKET_CHAT_ID,
  GET_TRUCK_LIST,
  GET_TRUCK_WORKFLOW_LIST,
  GET_USER_AUTH_TOKENS,
  GET_USER_DICTIONARY,
  GET_USER_ID_BY_PARAMS,
  GET_USER_INFO,
  GET_USER_ROLES,
  GET_USER_WORKFLOW_LIST,
  GET_USERS,
  GET_WIDGET_COMPANY,
  GET_WIDGETS,
  GET_WITHDRAW_LIST,
  MAKE_BANK_PAYMENT,
  MARK_ADMIN_MESSAGE_AS_READ,
  MOVE_TICKET_CHAT_MESSAGES,
  OPEN_ADMIN_TICKET_CHAT,
  RECALCULATE_TARIFF_ADMIN,
  SEND_ADMIN_MESSAGE,
  SET_ACCOUNTANCY_ORIGINAL_DOCS_RECEIVED,
  SET_ORDER_STATUS,
  SET_TRANSPORT_ORIGINAL_DOCS_RECEIVED,
  SYNC_TRANSPORT_WITH_ATI,
  UPDATE_MOVER_DOC,
  UPLOAD_BANK_DOC,
  UPLOAD_MOVER_DOC,
  VERIFY_MANUAL_DIGITIZATION,
} from "@/api/kuber-api/endpoints";
import { ManualDigitizationFormState, UncheckedVerificationsRequest } from "@/admin/data/form/types";
import {
  AdminMenuItem,
  AllManualCheckListShortFilter,
  Get1CCompanyListRequest,
  GetAdminAllManualCheckListRequest,
  GetAdminBankPaymentListRequest,
  GetAdminCreditListRequest,
  GetAdminDigitizationListRequest,
  GetAdminInvoiceListRequest,
  GetAdminOperationListRequest,
  GetAdminPostpaymentListRequest,
  GetAdminWithdrawListRequest,
  GetBriefDocPackageListRequest,
  GetBriefOrderListRequest,
  GetCustomerDocsPackageListRequest,
  GetMoverDocsListRequest,
  GetMoverDocsPackageListRequest,
  GetMoverTransportDocsListRequest,
  GetPromosRequest,
  GetWidgetCompanyFilterRequest,
  GetWidgetFilterRequest,
} from "@/domain/admin";
import { GetTicketMessagesRequest, UniqueEntity } from "@/domain";
import { mapQueryParam, prepareQueryParam, toQueryParam } from "@/data/utils";

export function createTn2022Manually(
  request: CreateTn2022ManuallyController_AgCreateTn2022Request,
): Promise<CreateTn2022ManuallyController_AgCreateTn2022Response> {
  return clientWithAuth.post(CREATE_TN_2022_MANUALLY, request).then((response) => response.data);
}

export async function checkVerificationManually(
  request: SendManualCheckController_ManualCheckRequest,
): Promise<SendManualCheckController_ResultBoolResponse> {
  return clientWithAuth.post(CHECK_VERIFICATION_MANUALLY, request).then((response) => response.data);
}

export async function generateClosingDocs(
  request: GenerateClosingDocumentsForOrders_AgGenerateClosingDocumentsForOrdersRequest,
): Promise<GenerateClosingDocumentsForOrders_ResultBoolResponse> {
  return clientWithAuth.post(GENERATE_CLOSING_DOCUMENTS, request).then((response) => response.data);
}

export async function setOrderStatus(
  request: SetOrderStatusController_AgSetOrderStatusWithParamsRequest,
): Promise<SetOrderStatusController_ResultBoolResponse> {
  return clientWithAuth.post(SET_ORDER_STATUS, request).then((response) => response.data);
}

export async function finishCustomerOrder(
  request: FinishOrderCustomerController_AgOrderIdCodeRequest,
): Promise<FinishOrderCustomerController_ResultBoolResponse> {
  return clientWithAuth.post(FINISH_ORDER_FOR_CUSTOMER, request).then((response) => response.data);
}

export async function finishMoverOrder(
  request: FinishOrderMoverController_AgOrderIdCodeRequest,
): Promise<FinishOrderMoverController_ResultBoolResponse> {
  return clientWithAuth.post(FINISH_ORDER_FOR_MOVER, request).then((response) => response.data);
}

export async function getAllManualDigitizations(): Promise<GetAllManualDigitizationsController_GetAllDigitizationsInStatusResponse> {
  return clientWithAuth.get(GET_ALL_MANUAL_DIGITIZATIONS).then((response) => response.data);
}

export async function verifyManualDigitization(
  request: ManualDigitizationFormState,
): Promise<ManualDocumentDigitizationController_DigitizeDocumentManuallyResponse> {
  return clientWithAuth.post(VERIFY_MANUAL_DIGITIZATION, request).then((response) => response.data);
}

export async function getAllUserVerifications(request: UncheckedVerificationsRequest): Promise<any> {
  const url = `${GET_ALL_VERIFICATIONS}?userId=${request.value}`;
  return clientWithAuth.get(url).then((response) => response.data);
}

export async function getOrdersForClosingDocs(
  request: UniqueEntity,
): Promise<GetAllOrdersAvailableForClosingDocumentsGeneration_AgGetAllOrdersAvailableForClosingDocumentsGenerationResponse> {
  return clientWithAuth
    .get(`/v1.0/admin/orders/document/${request.uuid}/get-all-available-closing-generation`)
    .then((response) => response.data);
}

export async function getCustomerOrdersReadyForFinish(
  request: UniqueEntity,
): Promise<GetAllOrdersReadyToBeClosedCustomerController_AgOrderIdCodeListResponse> {
  const url = `${GET_CUSTOMER_ORDERS_READY_FOR_FINISH}?customer_id=${request.uuid}`;
  return clientWithAuth.get(url).then((response) => response.data);
}

export async function getMoverOrdersReadyForFinish(
  request: UniqueEntity,
): Promise<GetAllOrdersReadyToBeClosedMoverController_AgOrderIdCodeListResponse> {
  const url = `${GET_MOVER_ORDERS_READY_FOR_FINISH}?mover_id=${request.uuid}`;
  return clientWithAuth.get(url).then((response) => response.data);
}

export async function syncTransportWithAti(request: {
  userId: string;
  value: string;
}): Promise<ManualSyncAtiController_ResultBoolResponse> {
  const url = `${SYNC_TRANSPORT_WITH_ATI}?userId=${request.value}`;
  return clientWithAuth.get(url).then((response) => response.data);
}

export async function addCredit(
  request: AddCreditCustomerAdminController_CreditCustomerRequest,
): Promise<AddCreditCustomerAdminController_CreditCustomerResponse> {
  return clientWithAuth.post(ADD_CREDIT, request).then((response) => response.data);
}

export async function getUserIdByParams(request: {
  identifier: string;
  value: string;
}): Promise<GetUserIdByParamsController_UserIdResponse> {
  const url = `${GET_USER_ID_BY_PARAMS}?${request.identifier}=${request.value}`;
  return clientWithAuth.get(url).then((response) => response.data);
}

export async function editUser(
  request: EditUserProfileController_EditUserProfileRequest,
): Promise<EditUserProfileController_ResultBoolResponse> {
  return clientWithAuth.post(EDIT_USER, request).then((response) => response.data);
}

export async function getUserRoles(request: { userId: string }): Promise<GetUserRolesController_UserRolesResponse> {
  const url = `${GET_USER_ROLES}?userId=${request.userId}`;
  return clientWithAuth.get(url).then((response) => response.data);
}

export async function getUsers(
  request: GetUsersListController_AgUsersListRequest,
): Promise<GetUsersListController_AgUserListResponse> {
  return clientWithAuth.post(GET_USERS, request).then((response) => response.data);
}

export async function calculateTariffFirstMile(
  request: AdminTariffsFirstMileController_PublicTariffCalculationFirstMileRequest,
): Promise<AdminTariffsFirstMileController_AdminTariffCalculationResponse> {
  return clientWithAuth.post(CALCULATE_TARIFF_FIRST_MILE_ADMIN, request).then((response) => response.data);
}

export async function calculateTariffLastMile(
  request: AdminTariffsLastMileController_PublicTariffCalculationLastMileRequest,
): Promise<AdminTariffsLastMileController_AdminTariffCalculationResponse> {
  return clientWithAuth.post(CALCULATE_TARIFF_LAST_MILE_ADMIN, request).then((response) => response.data);
}

export async function calculateTariffEmptyMile(
  request: AdminTariffEmptyMileController_PublicTariffCalculationEmptyContainerRequest,
): Promise<AdminTariffEmptyMileController_AdminTariffCalculationResponse> {
  return clientWithAuth.post(CALCULATE_TARIFF_EMPTY_MILE_ADMIN, request).then((response) => response.data);
}

export async function generateCustomerDocumentPackage(
  request: GenerateCustomerDocumentPackageController_AgGenerateCustomerDocumentPackageRequest,
): Promise<GenerateCustomerDocumentPackageController_ResultBoolResponse> {
  return clientWithAuth.post(GENERATE_CUSTOMER_DOCUMENT_PACKAGE, request).then((response) => response.data);
}

export async function getUserInfo(
  request: GetUserInfoByAdminController_UserIdAdminRequest,
): Promise<GetUserInfoByAdminController_UserInfoAdminResponse> {
  return clientWithAuth
    .post<GetUserInfoByAdminController_UserInfoAdminResponse>(GET_USER_INFO, request)
    .then((response) => response.data);
}

// SUPPORT

export function getAdminTicketChatDirectory(request: {
  user_id: string;
}): Promise<GetShortTicketsAdminController_ShortTicketListResponse> {
  const url = `${ADMIN_TICKET_CHAT_DIRECTORY}?user_id=${request.user_id}`;
  return clientWithAuth
    .get<GetShortTicketsAdminController_ShortTicketListResponse>(url)
    .then((response) => response.data);
}

export function getAdminTicketChats(
  request: GetTicketListAdminController_TicketListAdminRequest,
): Promise<GetTicketListAdminController_TicketListAdminResponse> {
  return clientWithAuth
    .post<GetTicketListAdminController_TicketListAdminResponse>(ADMIN_TICKET_CHAT_LIST, request)
    .then((response) => response.data);
}

export function getAdminTicketChatMessages(
  request: GetTicketMessagesRequest,
): Promise<GetTicketMessageListAdminController_TicketMessageListAdminResponse> {
  const url = `${ADMIN_TICKET_CHAT_MESSAGE_LIST}?ticket_id=${request.ticketId}&offset=${request.pagination.offset}&limit=${request.pagination.limit}`;
  return clientWithAuth
    .get<GetTicketMessageListAdminController_TicketMessageListAdminResponse>(url)
    .then((response) => response.data);
}

export function changeAdminTicketPinnedStatus(
  request: ChangeTicketPinnedStatusAdminController_NewTicketPinnedStatusRequest,
): Promise<ChangeTicketPinnedStatusAdminController_ResultBoolResponse> {
  return clientWithAuth
    .post<ChangeCustomerTicketPinnedStatusController_ResultBoolResponse>(ADMIN_CHANGE_TICKET_PINNED_STATUS, request)
    .then((response) => response.data);
}

export function closeTicketChat(
  request: CloseTicketController_TicketCloseRequest,
): Promise<CloseTicketController_ResultBoolResponse> {
  return clientWithAuth
    .post<CloseTicketController_ResultBoolResponse>(CLOSE_TICKET_CHAT, request)
    .then((response) => response.data);
}

export function getAdminUnreadMessageCount(request: {
  ticketId: string;
}): Promise<GetUnreadTicketMessageCountAdminController_UnreadTicketMessageCountResponse> {
  const url = `${ADMIN_UNREAD_MESSAGE_COUNT}?ticket_id=${request.ticketId}`;
  return clientWithAuth
    .get<GetUnreadTicketMessageCountAdminController_UnreadTicketMessageCountResponse>(url)
    .then((response) => response.data);
}

export function sendAdminTicketChatMessage(
  request: SendTicketMessageAdminController_NewTicketMessageAdminRequest,
): Promise<SendTicketMessageAdminController_TicketMessageIdAdminResponse> {
  return clientWithAuth
    .post<SendTicketMessageAdminController_TicketMessageIdAdminResponse>(SEND_ADMIN_MESSAGE, request)
    .then((response) => response.data);
}

export function markAdminMessagesAsRead(
  request: MarkMoverMessagesAsReadController_MessagesMarkAsReadRequest,
): Promise<MarkMessagesAsReadAdminController_ResultBoolResponse> {
  return clientWithAuth
    .post<MarkMoverMessagesAsReadController_ResultBoolResponse>(MARK_ADMIN_MESSAGE_AS_READ, request)
    .then((response) => response.data);
}

export function openAdminTicketChat(
  request: OpenTicketAdminController_TicketAndTicketMessageAdminRequest,
): Promise<OpenTicketAdminController_TicketIdResponse> {
  return clientWithAuth
    .post<OpenTicketAdminController_TicketIdResponse>(OPEN_ADMIN_TICKET_CHAT, request)
    .then((response) => response.data);
}

export function moveTicketChatMessages(
  request: MoveTicketMessageController_TicketMessageMoveRequest,
): Promise<MoveTicketMessageController_ResultBoolResponse> {
  return clientWithAuth
    .post<MoveTicketMessageController_ResultBoolResponse>(MOVE_TICKET_CHAT_MESSAGES, request)
    .then((response) => response.data);
}

export function deleteTicketChatMessages(
  request: DeleteTicketMessageAdminController_TicketMessageDeleteAdminRequest,
): Promise<DeleteTicketMessageAdminController_ResultBoolResponse> {
  return clientWithAuth
    .post<DeleteTicketMessageAdminController_ResultBoolResponse>(DELETE_TICKET_CHAT_MESSAGES, request)
    .then((response) => response.data);
}

export function getClosedTicketChatInfo(request: {
  ticketId: string;
}): Promise<GetClosedTicketInfoByAdminController_ClosedTicketInfoAdminResponse> {
  const url = `${GET_CLOSED_TICKET_INFO}?ticket_id=${request.ticketId}`;
  return clientWithAuth
    .get<GetClosedTicketInfoByAdminController_ClosedTicketInfoAdminResponse>(url)
    .then((response) => response.data);
}

export function getMoveMessageFromInfo(request: {
  messageId: string;
}): Promise<GetMovedFromOtherTicketMessageInfoAdminController_MovedFromOtherTicketMessageInfoResponse> {
  const url = `${GET_MOVED_FROM_MESSAGE_INFO}?message_id=${request.messageId}`;
  return clientWithAuth
    .get<GetMovedFromOtherTicketMessageInfoAdminController_MovedFromOtherTicketMessageInfoResponse>(url)
    .then((response) => response.data);
}

export function getMoveMessageToInfo(request: {
  messageId: string;
}): Promise<GetMovedToOtherTicketMessageInfoAdminController_MovedToOtherTicketMessageInfoResponse> {
  const url = `${GET_MOVED_MESSAGE_INFO}?message_id=${request.messageId}`;
  return clientWithAuth
    .get<GetMovedToOtherTicketMessageInfoAdminController_MovedToOtherTicketMessageInfoResponse>(url)
    .then((response) => response.data);
}

export function getTicketChatId(
  request: GetTicketIdAdminController_TicketTitleAndUserCodeRequest,
): Promise<GetTicketIdAdminController_TicketIdNullableResponse> {
  return clientWithAuth
    .post<GetTicketIdAdminController_TicketIdNullableResponse>(GET_TICKET_CHAT_ID, request)
    .then((response) => response.data);
}

export async function getOrdersWithoutContainers(): Promise<GetAllOrdersWithoutContainerNumberController_GetAllOrdersWithoutContainerNumberResponse> {
  return clientWithAuth.get(GET_ORDERS_WITHOUT_CONTAINERS).then((response) => response.data);
}

export async function addContainerToOrder(
  request: AddContainerNumberToOrderController_AgAddContainerNumberToOrderRequest,
): Promise<AddContainerNumberToOrderController_AddContainerNumberToOrderResponse> {
  return clientWithAuth.post(ADD_CONTAINER_TO_ORDER, request).then((response) => response.data);
}

export async function getPromos(
  request: GetPromosRequest,
): Promise<GetPromoCodesCustomerAdminController_TariffCalculationPromoCodesCustomerAdminResponse> {
  const customerUuid = request.customerUuid ? `&customer_uuid=${request.customerUuid}` : "";
  const promo = request.promoStatus ? `&promocode_status=${request.promoStatus}` : "";
  // const url = `${GET_PROMOS}?offset=${request.pagination.offset}&limit=${request.pagination.limit}&sorting_order_by=${request.sorting.direction}&sorting_field=${request.sorting.field}${promo}${customerUuid}`;
  const url = `${GET_PROMOS}?offset=${request.pagination.offset}&limit=${request.pagination.limit}`;
  return clientWithAuth.get(url).then((response) => response.data);
}

export async function getPromoInfo(): Promise<GetPromoCodeInfoAdminController_TariffCalculationPromoCodeInfoAdminResponse> {
  return clientWithAuth.get(GET_PROMO_INFO).then((response) => response.data);
}

export async function createPromo(
  request: CreatePromoCodesAdminController_NewPromoCodesRequest,
): Promise<CreatePromoCodesAdminController_ResultBoolResponse> {
  return clientWithAuth.post(CREATE_PROMO, request).then((response) => response.data);
}

export async function createSpecialPromo(
  request: CreatePromoCodesSpecialAdminController_NewPromoCodesRequest,
): Promise<CreatePromoCodesSpecialAdminController_ResultBoolResponse> {
  return clientWithAuth.post(CREATE_SPECIAL_PROMO, request).then((response) => response.data);
}

export async function changePromo(
  request: UpdateStatusPromCodeAdminController_StatusPromoCodeRequest,
): Promise<UpdateStatusPromCodeAdminController_ResultBoolResponse> {
  return clientWithAuth.post(CHANGE_PROMO_STATUS, request).then((response) => response.data);
}

export async function addRoles(
  request: ChangeUserRolesController_ChangeUserRolesRequest,
): Promise<ChangeUserRolesController_ResultBoolResponse> {
  return clientWithAuth.post(ADD_ROLES, request).then((response) => response.data);
}

export async function getOrderList(
  request: GetOrderListAdminController_AgGetOrderListAdminRequest,
): Promise<GetOrderListAdminController_AgOrderListAdminResponse> {
  return clientWithAuth.post(GET_ORDER_LIST, request).then((response) => response.data);
}

export async function addFileToExistingMoverPackage(
  request: AddFileToMoverDocumentPackageAdminController_AgAddFileToMoverDocumentPackageRequest,
): Promise<AddFileToMoverDocumentPackageAdminController_ResultBoolResponse> {
  return clientWithAuth.post(ADD_FILE_TO_EXISTING_MOVER_PACKAGE, request).then((response) => response.data);
}

export async function createNewMoverPackage(
  request: CreateNewMoverDocumentPackageAdminController_AgCreateNewMoverDocumentPackageRequest,
): Promise<CreateNewMoverDocumentPackageAdminController_ResultBoolResponse> {
  return clientWithAuth.post(CREATE_NEW_MOVER_PACKAGE, request).then((response) => response.data);
}

// TODO make filter via Map native structure and remove its fields via Map native methods and not via toQueryParam
export async function getMoverDocsList(
  request: GetMoverDocsListRequest,
): Promise<GetMoverDocumentPackageFileEntityListAdminController_AgMoverDocumentPackageFileEntityListAdminResponse> {
  const status = toQueryParam("status", request.status);
  const moverId = toQueryParam("mover_id", request.moverId);
  const url = `${GET_MOVER_DOCS_LIST}?offset=${request.pagination.offset}&limit=${request.pagination.limit}${moverId}${status}`;
  return clientWithAuth.get(url).then((response) => response.data);
}

export async function getMoverTransportDocsList(
  request: GetMoverTransportDocsListRequest,
): Promise<GetMoverTransportDocumentsListAdminController_AgMoverTransportDocumentsListAdminResponse> {
  const moverId = toQueryParam("mover_id", request.moverId);
  const url = `${GET_MOVER_TRANSPORT_DOCS_LIST}?offset=${request.pagination.offset}&limit=${request.pagination.limit}${moverId}`;
  return clientWithAuth.get(url).then((response) => response.data);
}

export async function getMoverDocsPackagesList(
  request: GetMoverDocsPackageListRequest,
): Promise<GetMoverDocumentPackageEntityListAdminController_AgMoverDocumentPackageEntityListAdminResponse> {
  const status = toQueryParam("status", request.status);
  const moverId = toQueryParam("mover_id", request.moverId);
  const url = `${GET_MOVER_DOCS_PACKAGE}?offset=${request.pagination.offset}&limit=${request.pagination.limit}${moverId}${status}`;
  return clientWithAuth.get(url).then((response) => response.data);
}

export async function getCustomerDocsPackagesList(
  request: GetCustomerDocsPackageListRequest,
): Promise<GetCustomerDocumentPackageEntityListAdminController_AgCustomerDocumentPackageListAdminResponse> {
  const status = toQueryParam("status", request.status);
  const customerId = toQueryParam("customer_id", request.customerId);
  const url = `${GET_CUSTOMER_DOCS_PACKAGE}?offset=${request.pagination.offset}&limit=${request.pagination.limit}${customerId}${status}`;
  return clientWithAuth.get(url).then((response) => response.data);
}

export async function updateMoverDoc(
  request: UpdateMoverDocumentPackageFileAdminController_AgUpdateMoverDocumentPackageFileStatusRequest,
): Promise<UpdateMoverDocumentPackageFileAdminController_ResultBoolResponse> {
  return clientWithAuth.post(UPDATE_MOVER_DOC, request).then((response) => response.data);
}

export async function uploadMoverDoc(
  request: UploadMoverDocumentPackageFileAdminController_AgUploadMoverDocumentPackageFileAdminRequest,
): Promise<UploadMoverDocumentPackageFileAdminController_ResultBoolResponse> {
  return clientWithAuth.post(UPLOAD_MOVER_DOC, request).then((response) => response.data);
}

export async function uploadBankDoc(
  request: AddBankStatementBillAdminController_BankStatementBillAGAdminRequest,
): Promise<AddBankStatementBillAdminController_ResultBoolResponse> {
  return clientWithAuth.post(UPLOAD_BANK_DOC, request).then((response) => response.data);
}

export async function createMoverWithdrawRequest(
  request: WithdrawMoverBalanceAdminController_ApplicationWithdrawAdminRequest,
): Promise<WithdrawMoverBalanceAdminController_ResultBoolResponse> {
  return clientWithAuth.post(CREATE_MOVER_WITHDRAW_REQUEST, request).then((response) => response.data);
}

export async function makeBankPayment(
  request: AddBankBillAdminController_BankPersonalBillAGRequest,
): Promise<AddBankBillAdminController_ResultBoolResponse> {
  return clientWithAuth.post(MAKE_BANK_PAYMENT, request).then((response) => response.data);
}

export async function getWithdrawList(
  request: GetAdminWithdrawListRequest,
): Promise<GetBillingApplicationWithdrawAdminController_BillingApplicationWithdrawAdminResponse> {
  const optionalQueryParams = mapQueryParam(prepareQueryParam(request.filter));
  const sorting = `&sorting_order_by=${request.sorting.direction}&sorting_field=${request.sorting.field}`;
  const url = `${GET_WITHDRAW_LIST}?offset=${request.pagination.offset}&limit=${request.pagination.limit}${optionalQueryParams}`;
  return clientWithAuth.get(url).then((response) => response.data);
}

export async function getCreditList(
  request: GetAdminCreditListRequest,
): Promise<GetBillingCreditIssuedCustomerAdminController_CreditIssuedCustomerResponse> {
  const optionalQueryParams = mapQueryParam(prepareQueryParam(request.filter));
  const sorting = `&sorting_order_by=${request.sorting.direction}&sorting_field=${request.sorting.field}`;
  const url = `${GET_CREDIT_LIST}?offset=${request.pagination.offset}&limit=${request.pagination.limit}${optionalQueryParams}`;
  return clientWithAuth.get(url).then((response) => response.data);
}

export async function getInvoiceList(
  request: GetAdminInvoiceListRequest,
): Promise<GetBillingInvoiceAdminController_BillingInvoiceAdminResponse> {
  const optionalQueryParams = mapQueryParam(prepareQueryParam(request.filter));
  const sorting = `&sorting_order_by=${request.sorting.direction}&sorting_field=${request.sorting.field}`;
  const url = `${GET_INVOICE_LIST}?offset=${request.pagination.offset}&limit=${request.pagination.limit}${optionalQueryParams}`;
  return clientWithAuth.get(url).then((response) => response.data);
}

export async function getOperationList(
  request: GetAdminOperationListRequest,
): Promise<GetBillingHistoryPersonalOperationAdminController_HistoryPersonalOperationAdminResponse> {
  const optionalQueryParams = mapQueryParam(prepareQueryParam(request.filter));
  const sorting = `&sorting_order_by=${request.sorting.direction}&sorting_field=${request.sorting.field}`;
  const url = `${GET_OPERATION_LIST}?offset=${request.pagination.offset}&limit=${request.pagination.limit}${optionalQueryParams}`;
  return clientWithAuth.get(url).then((response) => response.data);
}

export async function getPostpaymentList(
  request: GetAdminPostpaymentListRequest,
): Promise<GetBillingCreditOrderCustomerAdminController_CreditOrderCustomerAdminResponse> {
  const optionalQueryParams = mapQueryParam(prepareQueryParam(request.filter));
  const sorting = `&sorting_order_by=${request.sorting.direction}&sorting_field=${request.sorting.field}`;
  const url = `${GET_POSTPAYMENT_LIST}?offset=${request.pagination.offset}&limit=${request.pagination.limit}${optionalQueryParams}`;
  return clientWithAuth.get(url).then((response) => response.data);
}

export async function getBankPaymentList(
  request: GetAdminBankPaymentListRequest,
): Promise<GetBillingBankBillAdminController_BankBillOperationAdminResponse> {
  const optionalQueryParams = mapQueryParam(prepareQueryParam(request.filter));
  const sorting = `&sorting_order_by=${request.sorting.direction}&sorting_field=${request.sorting.field}`;
  const url = `${GET_BANK_PAYMENT_LIST}?offset=${request.pagination.offset}&limit=${request.pagination.limit}${optionalQueryParams}`;
  return clientWithAuth.get(url).then((response) => response.data);
}

export async function setOriginalDocumentsSend(
  request: SetCustomerDocumentPackageOriginalDocumentsSentController_AgSetCustomerDocumentPackageOriginalDocumentsSentRequest,
): Promise<SetCustomerDocumentPackageOriginalDocumentsSentController_ResultBoolResponse> {
  return clientWithAuth.post(FIXATE_SEND_ORIGINALS, request).then((response) => response.data);
}

export async function getCustomerStatusChain(request: {
  orderId: string;
}): Promise<GetOrderCustomerStatusChainAdminController_AgOrderCustomerStatusChainAdminResponse> {
  const url = GET_CUSTOMER_ORDER_STATUS_CHAIN + request.orderId;
  return clientWithAuth.get(url).then((response) => response.data);
}

export async function getMoverStatusChain(request: {
  orderId: string;
}): Promise<GetOrderMoverStatusChainAdminController_AgOrderMoverStatusChainAdminResponse> {
  const url = GET_MOVER_ORDER_STATUS_CHAIN + request.orderId;
  return clientWithAuth.get(url).then((response) => response.data);
}

export async function addDocToOrder(
  request: AddDocumentToOrderController_AgAddDocumentToOrderRequest,
): Promise<AddDocumentToOrderController_ResultBoolResponse> {
  return clientWithAuth.post(ADD_DOC_TO_ORDER, request).then((response) => response.data);
}

export async function getCompletedWorkAct(request: {
  user_uuid: string | null;
}): Promise<GetBillingCompletedWorkActAdminController_BillingCompletedWorkActAdminResponse> {
  const user_uuid = request.user_uuid ? `?user_uuid=${request.user_uuid}` : "";
  const url = GET_COMPLETED_WORK_ACT + user_uuid;
  return clientWithAuth.get(url).then((response) => response.data);
}

export async function changeTransportOwner(
  request: ChangeTransportOwnerController_ChangeTransportOwnerRequest,
): Promise<ChangeTransportOwnerController_ResultBoolResponse> {
  return clientWithAuth.post(CHANGE_TRANSPORT_OWNER, request).then((response) => response.data);
}

export async function setTransportOriginalDocsReceived(
  request: SetMoverTransportDocumentOriginalReceivedController_AgMoverTransportDocumentOriginalReceivedRequest,
): Promise<SetMoverTransportDocumentOriginalReceivedController_ResultBoolResponse> {
  return clientWithAuth.post(SET_TRANSPORT_ORIGINAL_DOCS_RECEIVED, request).then((response) => response.data);
}

export async function setAccountancyOriginalDocsReceived(
  request: SetMoverDocumentPackageFileOriginalReceivedController_AgMoverDocumentPackageFileOriginalReceivedRequest,
): Promise<SetMoverDocumentPackageFileOriginalReceivedController_ResultBoolResponse> {
  return clientWithAuth.post(SET_ACCOUNTANCY_ORIGINAL_DOCS_RECEIVED, request).then((response) => response.data);
}

export async function getDriverList(
  request: GetDriverListController_DriverListRequest,
): Promise<GetDriverListController_DriverListResponse> {
  return clientWithAuth.post(GET_DRIVER_LIST, request).then((response) => response.data);
}

export async function getTruckList(
  request: GetTruckListController_TruckAndSemiTrailerListRequest,
): Promise<GetTruckListController_TruckListResponse> {
  return clientWithAuth.post(GET_TRUCK_LIST, request).then((response) => response.data);
}

export async function getSemitrailerList(
  request: GetSemiTrailerListController_TruckAndSemiTrailerListRequest,
): Promise<GetSemiTrailerListController_SemiTrailerListResponse> {
  return clientWithAuth.post(GET_SEMITRAILER_LIST, request).then((response) => response.data);
}

export async function cancelOrder(
  request: CancelOrderAdminController_AgOrderCancelRequest,
): Promise<CancelOrderAdminController_ResultBoolResponse> {
  return clientWithAuth.post(CANCEL_ADMIN_ORDER, request).then((response) => response.data);
}

export async function getAdminMenu(): Promise<AdminMenuItem[]> {
  return clientDefault.get(GET_ADMIN_MENU).then((response) => response.data);
}

export async function changeFinishDate(
  request: ChangeContainerDeliveredAtController_AgChangeContainerDeliveredAtRequest,
): Promise<ChangeContainerDeliveredAtController_ResultBoolResponse> {
  return clientWithAuth.post(CHANGE_FINISH_DATE, request).then((response) => response.data);
}

export async function getUserDictionary(
  request: {
    account_type: "customer" | "mover";
  } | null,
): Promise<GetUserListLightController_AdminUserListLightResponse> {
  const queryParams = request ? "?account_type=" + request.account_type : "";
  const url = `${GET_USER_DICTIONARY}${queryParams}`;
  return clientWithAuth.get(url).then((response) => response.data);
}

export async function getDriverWorkflowList(request: {
  driver_uniq_code: string;
}): Promise<GetDriverWorkFlowController_TransportWorkFlowResponse> {
  const url = `${GET_DRIVER_WORKFLOW_LIST}?driver_uniq_code=${request.driver_uniq_code}`;
  return clientWithAuth.get(url).then((response) => response.data);
}

export async function getTruckWorkflowList(request: {
  truck_uniq_code: string;
}): Promise<GetTruckWorkFlowController_TransportWorkFlowResponse> {
  const url = `${GET_TRUCK_WORKFLOW_LIST}?truck_uniq_code=${request.truck_uniq_code}`;
  return clientWithAuth.get(url).then((response) => response.data);
}

export async function getSemitrailerWorkflowList(request: {
  semi_trailer_uniq_code: string;
}): Promise<GetSemiTrailerWorkFlowController_TransportWorkFlowResponse> {
  const url = `${GET_SEMITRAILER_WORKFLOW_LIST}?semi_trailer_uniq_code=${request.semi_trailer_uniq_code}`;
  return clientWithAuth.get(url).then((response) => response.data);
}

export async function getUserWorkflowList(request: {
  user_id: string;
}): Promise<GetUserWorkFlowController_WorkFlowResponse> {
  const url = `${GET_USER_WORKFLOW_LIST}?user_id=${request.user_id}`;
  return clientWithAuth.get(url).then((response) => response.data);
}

export async function getAllWorkflowCheckList(
  request: GetAdminAllManualCheckListRequest,
): Promise<GetAllManualChecksController_WorkflowAllManualChecksResponse> {
  const shortFilterRequest: AllManualCheckListShortFilter = {
    filter_user_id: request.filter.filter_user_id,
    filter_inn_company: request.filter.filter_inn_company,
  };
  const optionalQueryParams = mapQueryParam(prepareQueryParam(shortFilterRequest));
  const categories = request.filter.filter_category_check.map((cc) => "filter_category_checks[]=" + cc + "&").join("");
  const url = `${GET_ALL_WORKFLOW_CHECK_LIST}?${categories}offset=${request.pagination.offset}&limit=${request.pagination.limit}${optionalQueryParams}`;
  return clientWithAuth.get(url).then((response) => response.data);
}

export async function getBriefOrders(
  request: GetBriefOrderListRequest,
): Promise<GetOrderBriefInfoListAdminController_AgGetOrderBriefInfoListAdminResponse> {
  const optionalQueryParams = mapQueryParam(prepareQueryParam(request));
  const url = `${GET_BRIEF_ORDER_LIST}${optionalQueryParams.replace("&", "?")}`;
  return clientWithAuth.get(url).then((response) => response.data);
}

export async function getBriefDocPackages(
  request: GetBriefDocPackageListRequest,
): Promise<GetMoverDocumentPackageBriefInfoListAdminController_AgGetMoverDocumentPackageBriefInfoListAdminResponse> {
  const optionalQueryParams = mapQueryParam(prepareQueryParam(request));
  const url = `${GET_BRIEF_DOC_PACKAGE_LIST}${optionalQueryParams.replace("&", "?")}`;
  return clientWithAuth.get(url).then((response) => response.data);
}

export async function getCheckUserDetails(request: {
  check_uuid: string;
}): Promise<GetCheckDetailsUserController_WorkFlowCheckDetailsUserResponse> {
  const url = `${GET_CHECK_USER_DETAILS}?check_uuid=${request.check_uuid}`;
  return clientWithAuth.get(url).then((response) => response.data);
}

export async function getCheckDriverDetails(request: {
  check_uuid: string;
}): Promise<GetCheckDetailsDriverController_WorkFlowCheckDetailsDriverResponse> {
  const url = `${GET_CHECK_DRIVER_DETAILS}?check_uuid=${request.check_uuid}`;
  return clientWithAuth.get(url).then((response) => response.data);
}

export async function getCheckSemiTrailerDetails(request: {
  check_uuid: string;
}): Promise<GetCheckDetailsSemiTrailerController_WorkFlowCheckDetailsSemiTrailerResponse> {
  const url = `${GET_CHECK_SEMI_TRAILER_DETAILS}?check_uuid=${request.check_uuid}`;
  return clientWithAuth.get(url).then((response) => response.data);
}

export async function getCheckTruckDetails(request: {
  check_uuid: string;
}): Promise<GetCheckDetailsTruckController_WorkFlowCheckDetailsTruckResponse> {
  const url = `${GET_CHECK_TRUCK_DETAILS}?check_uuid=${request.check_uuid}`;
  return clientWithAuth.get(url).then((response) => response.data);
}

export async function closePostpayment(
  request: AdvancedCustomerCreditRepaymentAdminController_CustomerCreditRepaymentAdminAGRequest,
): Promise<AdvancedCustomerCreditRepaymentAdminController_CustomerCreditRepaymentAdminResponse> {
  return clientWithAuth.post(CLOSE_POSTPAYMENT, request).then((response) => response.data);
}

export async function getUserAuthTokens(request: {
  user_id: string;
}): Promise<UserSpoofingController_SecurityTokenResponse> {
  const url = `${GET_USER_AUTH_TOKENS}?user_uuid=${request.user_id}`;
  return clientWithAuth.get(url).then((response) => response.data);
}

export async function getDigitizationList(
  request: GetAdminDigitizationListRequest,
): Promise<GetDigitizationsListAdminController_AgGetDigitizationsListAdminResponse> {
  const url = `${GET_DIGITIZATION_LIST}?offset=${request.pagination.offset}&limit=${request.pagination.limit}&status=${request.status}`;
  return clientWithAuth.get(url).then((response) => response.data);
}

export async function declineDocument(
  request: DeclineDocumentDigitizationAdminController_AgDeclineDigitizationAdminRequest,
): Promise<DeclineDocumentDigitizationAdminController_ResultBoolResponse> {
  return clientWithAuth.post(DECLINE_DOCUMENT, request).then((response) => response.data);
}

export async function getCompanyList(
  request: Get1CCompanyListRequest,
): Promise<GetCompanyWith1CInfoListAdminController_AgCompanyWith1CInfoListAdminResponse> {
  const inn_company = request.filter.inn_company ? "&inn_company=" + request.filter.inn_company : "";
  const url = `${GET_COMPANY_LIST}?offset=${request.pagination.offset}&limit=${request.pagination.limit}${inn_company}`;
  return clientWithAuth.get(url).then((response) => response.data);
}

export async function changeContractorInfo(
  request: UpdateContractor1CInfoAdminController_AgContractor1CInfoAdminRequest,
): Promise<UpdateContractor1CInfoAdminController_ResultBoolResponse> {
  return clientWithAuth.post(CHANGE_CONTRACTOR_INFO, request).then((response) => response.data);
}

export async function getWidgets(
  request: GetWidgetFilterRequest,
): Promise<GetWidgetOrderAggregateFilterController_WidgetOrdersResponse> {
  const filter = request ? `?aggregate_filter=${request.aggregate_filter}` : "";
  const url = `${GET_WIDGETS}${filter}`;
  return clientWithAuth.get(url).then((response) => response.data);
}

export async function recalculateTariff(
  request: AdminRecalculationTariffController_RecalculationTariffAdminRequest,
): Promise<AdminRecalculationTariffController_AdminTariffCalculationResponse> {
  return clientWithAuth.post(RECALCULATE_TARIFF_ADMIN, request).then((response) => response.data);
}

export async function changeTariff(
  request: UpdateTariffAdminController_AgRecalculationOrderTariffAdminRequest,
): Promise<UpdateTariffAdminController_ResultBoolResponse> {
  return clientWithAuth.post(CHANGE_TARIFF_ADMIN, request).then((response) => response.data);
}

export async function getWidgetCompany(
  request: GetWidgetCompanyFilterRequest,
): Promise<GetListCompanyWidgetOrderAGFilter_ListCompanyWidgetOrdersResponse> {
  const filter = request ? `?aggregate_company_filter=${request.aggregate_filter}` : "";
  const url = `${GET_WIDGET_COMPANY}${filter}`;
  return clientWithAuth.get(url).then((response) => response.data);
}
